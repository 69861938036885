export const CREATE_LIST = 'CREATE_LIST'
export const BEGIN_UPDATE_LIST = 'BEGIN_UPDATE_LIST'
export const COMMIT_UPDATE_LIST = 'COMMIT_UPDATE_LIST'
export const PREEMPT_UPDATE_LIST_ERROR = 'PREEMPT_UPDATE_LIST_ERROR'
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS'
export const UPDATE_LIST_ERROR = 'UPDATE_LIST_ERROR'
export const COMMIT_DELETE_LIST = 'COMMIT_DELETE_LIST'
// export const DUPLICATE_LIST = 'DUPLICATE_LIST'
export const BEGIN_REORDER_PLACES_WITHIN_LIST = 'BEGIN_REORDER_PLACES_WITHIN_LIST'
export const COMMIT_REORDER_PLACES_WITHIN_LIST = 'COMMIT_REORDER_PLACES_WITHIN_LIST'
export const BEGIN_REORDER_PLACES_BETWEEN_LISTS = 'BEGIN_REORDER_PLACES_BETWEEN_LISTS'
export const COMMIT_REORDER_PLACES_BETWEEN_LISTS = 'COMMIT_REORDER_PLACES_BETWEEN_LISTS'
export const REORDER_PLACES_BETWEEN_LISTS_SUCCESS = 'REORDER_PLACES_BETWEEN_LISTS_SUCCESS'
