import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './CoverImage.module.scss'

const CoverImage = ({ className, innerClass, photoUrl, backgroundFilter, hoverReveal, children }) => {
  const style = {
    backgroundImage: `url("${photoUrl}")`
  }

  const coverClass = cx(
    (hoverReveal) ? styles['background-hover-reveal'] : '',
    styles['cover-image'],
    className
  )

  const backgroundOverlayClass = cx(
    (backgroundFilter) ? styles[`background-overlay-${backgroundFilter}`] : '',
    styles['background-overlay'],
    innerClass
  )

  return (
    <div className={coverClass} style={style}>
      <div className={backgroundOverlayClass}>
        {children}
      </div>
    </div>
  )
}

CoverImage.propTypes = {
  className: PropTypes.string,
  innerClass: PropTypes.string,
  photoUrl: PropTypes.string.isRequired,
  backgroundFilter: PropTypes.oneOf(['top', 'bottom', 'full']),
  hoverReveal: PropTypes.bool,
  children: PropTypes.node
}

export default CoverImage
