
import {
  GET_FEATURE_FLAGS_SUCCESS
} from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FEATURE_FLAGS_SUCCESS:
      const data = action.data

      return {
        ...state,
        ...data,
        dateUpdated: new Date().getTime()
      }
    default:
      return {
        ...state
      }
  }
}
