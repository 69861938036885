import { combineReducers } from 'redux'
import userReducer from './user'
import collaboratorsReducer from './collaborators'

export default combineReducers({
  user: userReducer,
  collaborators: collaboratorsReducer
})

// ------------------------------------
// Selectors
// ------------------------------------

const all = Symbol('use this key as "match all" to bubble down all attributes')

const merge = (uid, attr, acc = {}) => {
  return {
    ...acc,
    [uid]: attr
  }
}

/**
 * Maps top-level keys to child values. You can compose this to selectively propagate mapping.
 * Sample Input = {
 *   user1: {
 *     list1: {
 *       isEditing: true
 *     }
 *   },
 *   user2: {
 *     list1: {
 *       isEditing: false,
 *       place1: {
 *         isEditing: true
 *       }
 *     }
 *   }
 * }
 * Sample Output = {
 *   list1: {
 *     isEditing: {
 *       user1: true,
 *       user2: false
 *     },
 *     place1: {
 *       isEditing: {
 *         user2: true
 *       }
 *     }
 *   }
 * }
 * @param {Object} bubbleDownHandlers Object containing composable function
 */
const bubbleDown = (bubbleDownHandlers = {}) => {
  const specialKeys = Object.keys(bubbleDownHandlers)
  return (uid, obj, acc = {}) => {
    const newState = acc
    for (const key of Object.keys(obj)) {
      if (bubbleDownHandlers[all]) {
        newState[key] = bubbleDownHandlers[all](uid, obj[key], acc[key])
      } else if (specialKeys.includes(key)) {
        newState[key] = bubbleDownHandlers[key](uid, obj[key], acc[key])
      } else {
        newState[key] = merge(uid, obj[key], acc[key])
      }
    }
    return newState
  }
}

const bubbleDownPlaces = bubbleDown()
const bubbleDownList = bubbleDown({ places: bubbleDownPlaces })
const bubbleDownLists = bubbleDown({ [all]: bubbleDownList })
const bubbleDownBoard = bubbleDown({ lists: bubbleDownLists })
const bubbleDownUser = bubbleDown({ board: bubbleDownBoard })

const selectBoardEditStates = (state) => {
  const editStates = state?.collaborators || {}

  let newState = {}
  for (const uid of Object.keys(editStates)) {
    newState = bubbleDownUser(uid, editStates[uid], newState)
  }
  return newState
}

export const selectors = {
  selectBoardEditStates
}
