
import produce from 'immer'
import merge from 'lodash/merge'
import {
  FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS,
  FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS
} from 'client/app/js/services/boards/types'
import { FETCH_PLACE_DETAILS_SUCCESS } from 'client/app/js/services/places/types'

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { places } = action.data?.entities ?? {}

  switch (action.type) {
    case FETCH_PLACE_DETAILS_SUCCESS:
      merge(draft, places)
      break
    case FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS:
    case FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS:
      merge(draft, places)
      break
  }
}, {})

// ------------------------------------
// Selectors
// ------------------------------------
const selectPlaceDetail = (state, gpid) => {
  return state[gpid]
}

export const selectors = {
  selectPlaceDetail
}
