import {
  FETCH_SITEMAP_BOARDS_FOR_LOCATION,
  FETCH_SITEMAP_PUBLIC_LOCATIONS
} from './types'
import { getLocationsUrl, getShareBoardRoute } from 'common/configs/routeMap'

const ACTION_HANDLERS = {
  [FETCH_SITEMAP_BOARDS_FOR_LOCATION]: (state, action) => {
    const { boards } = action.data
    const total = boards?.hits || 0
    const boardRes = boards?.boards || []
    const results = boardRes.map(({ _source }) => ({
      name: _source.title,
      url: getShareBoardRoute(_source.sharedBoardId)
    }))

    return {
      total,
      results
    }
  },
  [FETCH_SITEMAP_PUBLIC_LOCATIONS]: (state, action) => {
    const { locations } = action.data
    const total = locations?.hits || 0
    const cities = locations?.cities || []
    const after = locations?.after
    const results = cities.map((cityItem) => ({
      name: cityItem.name,
      url: getLocationsUrl(cityItem.name),
      count: cityItem.count
    }))

    return {
      total,
      results,
      after
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
