import React, { Component } from 'react'
import Navbar from './Navbar'
import context from './context'

export const NavLinksContext = context

// Removes the `fixed-top` class from the global-navbar
export const removeFixedNav = (WrappedComponent) => {
  const navbarId = 'hipherd-navbar'

  return class Wrapper extends Component {
    componentDidMount () {
      const navbar = document.getElementById(navbarId)
      navbar.classList.remove('fixed-top')
    }

    componentWillUnmount () {
      const navbar = document.getElementById(navbarId)
      navbar.classList.add('fixed-top')
    }

    render () {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }
}

export default Navbar
