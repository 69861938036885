import { combineReducers } from 'redux'
import { bindSelectors } from 'client/shared/js/utils/selector'
import tripsReducer from './trips'
import airbnbReducer from './airbnb'
import destinationsReducer, { selectors as destinationsSelectors } from './destinations'

export default combineReducers({
  trips: tripsReducer,
  airbnb: airbnbReducer,
  destinations: destinationsReducer
})

// ------------------------------------
// Selectors
// ------------------------------------

const selectDestinations = (state) => {
  return state.destinations
}

export const selectors = {
  selectDestinations,
  ...bindSelectors(selectDestinations, destinationsSelectors)
}
