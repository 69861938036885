import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import TextTruncate from 'react-text-truncate'
import CoverImage from 'client/shared/js/components/CoverImage'
import styles from './TallDestinationCard.module.scss'

const DEFAULT_IMAGE = '/static/assets/img/illustrations/best-place.svg'

const propTypes = {
  className: PropTypes.string,
  destination: PropTypes.object
}

const TallDestinationCard = ({ className, destination }) => {
  const { displayName, photo, numBoards } = destination

  const numTrips = `${numBoards} trips`

  return (
    <Card className={cx(className, styles.card)}>
      <CoverImage
        className={cx(styles['card-img'])}
        photoUrl={photo.urls.regular ?? DEFAULT_IMAGE}
        backgroundFilter={photo.urls.regular ? 'bottom' : undefined} />
      <CardBody className={cx(styles['card-title'])}>
        <CardTitle className={cx(styles['card-heading'])} tag='h5'>
          <TextTruncate text={displayName} line={1} />
        </CardTitle>
        {
          numBoards ? (
            <CardSubtitle tag='h6'>
              <TextTruncate text={numTrips} line={2} />
            </CardSubtitle>
          )
            : null
        }
      </CardBody>
    </Card>
  )
}

TallDestinationCard.propTypes = propTypes

export default TallDestinationCard
