import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BoardOption = ({ board }) => {
  const { title, locationText } = board

  return (
    <div className='board-search-option d-flex flex-row align-items-center'>
      <div className='badge-icon'>
        <FontAwesomeIcon icon={['fas', 'table-columns']} />
      </div>
      <div className='content-description'>
        <div>{title}</div>
        <h6>{locationText}</h6>
      </div>
    </div>
  )
}

BoardOption.defaultProps = {
  board: {}
}

BoardOption.propTypes = {
  board: PropTypes.object
}

export default BoardOption
