import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DestinationOption = ({ destination }) => {
  // eslint-disable-next-line camelcase
  const { structured_formatting } = destination

  return (
    <div className='d-flex flex-row align-items-center'>
      <div className='badge-icon destination'>
        <FontAwesomeIcon icon={['fas', 'tree-city']} />
      </div>
      <div className='content-description'>
        <div>{structured_formatting?.main_text ?? 'foo'}</div>
        <h6>{structured_formatting?.secondary_text ?? 'bar'}</h6>
      </div>
    </div>
  )
}

DestinationOption.defaultProps = {
  destination: {}
}

DestinationOption.propTypes = {
  destination: PropTypes.object
}

export default DestinationOption
