import moment from 'moment'

// TODO: move this to hipherd/core
export const convertTimestamp = (unixEpochMilliseconds) => {
  return moment(unixEpochMilliseconds).format('MMM YYYY')
}

/**
 * Convert's a UTC localtime format into the proper 'from now'
 * This is mostly used for getstream's time format
 * @param {String} isoDate The time in isoformat
 */
export const convertTimezoneFromNow = (isoDate) => {
  return moment(isoDate).parseZone().fromNow()
}

/**
 * Converts the timestamp to the following format: February 2020
 * @param {Number} unixEpochMilliseconds The timestamp in unix epoch milliseconds
 */
export const convertMonthYear = (unixEpochMilliseconds) => {
  return moment(unixEpochMilliseconds).format('MMMM YYYY')
}

/**
 * Formats start/end dates into the following format: 02/12/2020 - 02/14/2020
 * @param {Number} startDate The timestamp in unix epoch milliseconds
 * @param {*} endDate The timestamp in unix epoch milliseconds
 */
export const formatDateRange = (startDate, endDate) => {
  if (startDate || endDate) {
    const formatString = 'll' // Feb 20, 2023
    const formattedStartDate = startDate ? moment(startDate).format(formatString) : null
    const formattedEndDate = endDate ? moment(endDate).format(formatString) : null

    let formattedDateString
    if (formattedStartDate === formattedEndDate || !formattedEndDate) {
      formattedDateString = formattedStartDate
    } else {
      formattedDateString = `${formattedStartDate ?? ''} - ${formattedEndDate}`
    }

    return formattedDateString
  }

  return null
}
