import produce from 'immer'
import unionWith from 'lodash/unionWith'
import differenceWith from 'lodash/differenceWith'
import {
  FETCH_COLLABORATORS,
  ADD_COLLABORATOR,
  UPDATE_COLLABORATOR_ROLE,
  DELETE_COLLABORATOR,
  INVITE_COLLABORATOR_WITH_EMAIL,
  UNINVITE_COLLABORATOR
} from 'client/app/js/services/boards/types'
import { merge } from 'client/shared/js/utils/object'

// Do not use object destructing, immer does not support this since the `draft` object is a proxy
const comparator = (collaborator, otherCollaborator) => {
  return (collaborator.email)
    ? collaborator.email === otherCollaborator.email
    : collaborator.uid === otherCollaborator.uid
}

export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}
  const { boards } = action.data?.entities ?? {}

  switch (action.type) {
    case FETCH_COLLABORATORS:
      merge(draft, boards)
      break

    case ADD_COLLABORATOR:
    case INVITE_COLLABORATOR_WITH_EMAIL:
      draft[bid].collaborators = unionWith(draft[bid].collaborators, boards[bid].collaborators, comparator)
      break

    case UPDATE_COLLABORATOR_ROLE:
      // Create a dictionary of all the updated collaborators, and only merge the collaborators that match
      const updatedCollaborators = {}
      boards[bid].collaborators.forEach((collaborator) => {
        updatedCollaborators[collaborator.uid] = collaborator
      })

      draft[bid].collaborators = draft[bid].collaborators.map((collaborator) => {
        const updatedCollaborator = updatedCollaborators[collaborator.uid]

        return (updatedCollaborator) ? merge(collaborator, updatedCollaborator) : collaborator
      })
      break

    case DELETE_COLLABORATOR:
    case UNINVITE_COLLABORATOR:
      draft[bid].collaborators = differenceWith(draft[bid].collaborators, boards[bid].collaborators, comparator)
      break
  }
})
