import { GET_FEATURE_FLAGS_SUCCESS, UPDATE_USER_FEATURE_FLAGS } from './types'
import { checkAndRefreshUserToken } from '../auth/actions'
import featureFlagsApi from './api'
import userApi from '../user/api'

export const getFeatureFlags = () => {
  return (dispatch, getState) => {
    const existingFlags = getState().featureFlags

    if (existingFlags?.dateUpdated) {
      return
    }

    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        return featureFlagsApi.getFeatureFlags()
          .then((res) => {
            const result = res.data || {}

            dispatch({
              type: GET_FEATURE_FLAGS_SUCCESS,
              data: result
            })
          })
      })
  }
}

export const updateUserFeatureFlag = (userId, featureName, flagName, enabled = true) => {
  return (dispatch) => {
    if (featureName && flagName) {
      const featureFlags = {
        [flagName]: {
          enabled
        }
      }

      return dispatch(updateUserFeatureFlags(userId, featureName, featureFlags))
    }
  }
}

export const updateUserFeatureFlags = (userId, featureName, featureFlags) => {
  return (dispatch) => {
    return dispatch(checkAndRefreshUserToken())
      .then(() => {
        dispatch({
          type: UPDATE_USER_FEATURE_FLAGS,
          data: {
            featureName,
            featureFlags
          },
          track: {
            event: {
              name: 'Feature flag updated'
            }
          }
        })

        return userApi.updateFeatureFlags(userId, featureName, featureFlags)
      })
  }
}
