import {
  GET_STREAM_TOKENS,
  FETCH_NOTIFICATION_FEED,
  LOAD_MORE_NOTIFICATIONS,
  REMOVE_NOTIFICATION_FEED_ITEM,
  REMOVE_NOTIFICATION_FEED_GROUP
} from 'client/shared/js/services/user/types'

const initialState = {
  tokens: {
    timeline: '',
    notification: ''
  },
  notification: {}
}

const ACTION_HANDLERS = {
  [GET_STREAM_TOKENS]: (state, action) => {
    const tokens = action.data

    return {
      ...state,
      tokens: {
        ...state.tokens,
        ...tokens
      }
    }
  },
  [FETCH_NOTIFICATION_FEED]: (state, action) => {
    const notificationFeed = action.data

    return {
      ...state,
      notification: notificationFeed
    }
  },
  [LOAD_MORE_NOTIFICATIONS]: (state, action) => {
    const notificationFeed = action.data

    return {
      ...state,
      notification: {
        ...state.notification,
        ...notificationFeed,
        results: [
          ...state.notification.results,
          ...notificationFeed.results
        ]
      }
    }
  },
  [REMOVE_NOTIFICATION_FEED_ITEM]: (state, action) => {
    const activityId = action.data
    const notificationResults = [
      ...state.notification.results
    ]

    // Find the activity id within the notification result activities
    notificationResults.every((result) => {
      const activityIndex = result.activities.findIndex((activity) => activity.id === activityId)

      if (activityIndex > -1) {
        result.activities.splice(activityIndex, 1)
        return false
      }

      return true
    })

    return (activityId) ? {
      ...state,
      notification: {
        ...state.notification,
        results: [
          ...notificationResults
        ]
      }
    } : { ...state }
  },
  [REMOVE_NOTIFICATION_FEED_GROUP]: (state, action) => {
    const { groupId, deleted = [], failed = [] } = action.data
    const notificationResults = [
      ...state.notification.results
    ]

    // Find the group id within the notification results
    const groupIndex = notificationResults.findIndex(activityItem => activityItem.id === groupId)

    if (groupIndex > -1) {
      // Only remove the entire group if there are no failed deletes
      if (failed.length === 0) {
        notificationResults.splice(groupIndex, 1)
      } else {
        // Remove activity ids that are within the 'deleted' array result
        // Then replaces the original activities list with the updated one
        const activities = [...notificationResults[groupIndex].activities]
        const updatedActivities = activities.filter((activity) => !deleted.includes(activity.id))
        notificationResults.splice(groupIndex, 1, updatedActivities)
      }
    }

    return {
      ...state,
      notification: {
        ...state.notification,
        results: [
          ...notificationResults
        ]
      }
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
