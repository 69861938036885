/**
 * Formats the AutocompletePrediction interface into a more usable format
 * See: https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service?authuser=1#AutocompletePrediction
 * @param {Object} prediction Google prediction object
 */
export const formatPrediction = (prediction) => {
  return {
    gpid: prediction.place_id,
    name: prediction.structured_formatting.main_text,
    secondaryText: prediction.structured_formatting.secondary_text,
    detailedName: prediction.description,
    terms: prediction.terms,
    substringMatch: prediction.matched_substrings
  }
}

/**
 * Formats the Google Address Component Types into a more usable location format
 * See: https://developers.google.com/maps/documentation/geocoding/intro#Types
 * @param {Array} addressComponents The google address components
 */
export const formatAddressComponents = (addressComponents) => {
  const location = {}
  let street = ''

  addressComponents.forEach((addressComponent) => {
    addressComponent.types.forEach((type) => {
      switch (type) {
        case 'street_number':
          street = `${addressComponent['long_name']} ${street}`.trim()
          break
        case 'route':
          street = `${street} ${addressComponent['short_name']}`.trim()
          break
        case 'locality':
          location.city = addressComponent['long_name']
          break
        case 'administrative_area_level_1':
          location.administrative = addressComponent['long_name']
          break
        case 'country':
          location.country = addressComponent['long_name']
          location.countryCode = addressComponent['short_name'].toLowerCase()
          break
        case 'postal_code':
          location.postalCode = addressComponent['short_name']
          break
        default:
      }
    })
  })

  // Only set the street value if the string is valid
  location.street = street || null

  return location
}

export default {
  formatPrediction
}
