import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CoverImage from 'client/shared/js/components/CoverImage'
import UnsplashBackground from 'client/shared/js/components/UnsplashBackground'
import styles from './BoardCoverImage.module.scss'

const propTypes = {
  board: PropTypes.object
}

const BoardCoverImage = ({ board }) => {
  const { backgroundPhotos = {} } = board
  let background = null

  if (backgroundPhotos?.user?.urls) {
    background = (
      <CoverImage
        photoUrl={backgroundPhotos.user.urls.regular}
        backgroundFilter='bottom' />
    )
  } else if (backgroundPhotos?.unsplash) {
    background = (
      <UnsplashBackground
        backgroundPhoto={backgroundPhotos.unsplash}
        backgroundFilter='bottom'
        disableLinks
        disableAttribution />
    )
  } else {
    background = (
      <div className={cx(styles['illustration-bg'])}>
        <img src='/static/assets/img/illustrations/through-park.svg' className={cx([styles['illustration']])} />
      </div>
    )
  }

  return background
}

BoardCoverImage.propTypes = propTypes

export default BoardCoverImage
