// TODO: Use this method instead: https://stackoverflow.com/questions/69472624/font-awesome-6-beta-and-dynamic-icon-name-in-next-js
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faAirbnb
} from '@fortawesome/free-brands-svg-icons'
import {
  faXmark,
  faCircleXmark,
  faPlus,
  faCirclePlus,
  faCircleMinus,
  faEllipsis,
  faEllipsisVertical,
  faAngleRight,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faArrowLeft,
  faArrowRight,
  faCheck,
  faH1,
  faH2,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faListOl,
  faListUl,
  faClipboard,
  faLink,
  faShareNodes,
  faCloudArrowUp as falCloudArrowUp,
  faTrashCan,
  faGlobe,
  faAt,
  faHeart as falHeart,
  faStar as falStar,
  faCalendarPlus,
  faClock,
  faGripLinesVertical
} from '@fortawesome/pro-light-svg-icons'
import {
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faAnglesLeft,
  faAnglesRight,
  faUpRightFromSquare,
  faDown,
  faGear,
  faLockKeyhole,
  faCompass,
  faBedFront,
  faMemoPad,
  faMessages,
  faImage,
  faImages,
  faImagePolaroid,
  faTrash,
  faPen,
  faRotate,
  faPhoneFlip,
  faCircleExclamation,
  faCar,
  faCameraRetro,
  faBell,
  faTableColumns,
  faCircleUser,
  faUserGroup,
  faCrown,
  faCloudArrowUp,
  faLocationCrosshairs,
  faMapLocationDot,
  faLocationDot,
  faMagnifyingGlass,
  faHeart as fasHeart,
  faStar as fasStar,
  faNoteSticky,
  faCopy,
  faHashtag,
  faCommentQuestion,
  faCalendar,
  faTreeCity
} from '@fortawesome/pro-solid-svg-icons'
import './RegisterFontAwesomeIcons.scss'

library.add(
  // Brand icons
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faAirbnb,

  // Light icons
  faXmark,
  faCircleXmark,
  faPlus,
  faCirclePlus,
  faCircleMinus,
  faEllipsis,
  faEllipsisVertical,
  faAngleRight,
  falChevronLeft,
  falChevronRight,
  faArrowLeft,
  faArrowRight,
  faGlobe,
  faH1,
  faH2,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faListOl,
  faListUl,
  faClipboard,
  faLink,
  faShareNodes,
  faCheck,
  falCloudArrowUp,
  faTrashCan,
  faAt,
  falHeart,
  falStar,
  faCalendarPlus,
  faClock,
  faGripLinesVertical,

  // Solid icons
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faAnglesLeft,
  faAnglesRight,
  faUpRightFromSquare,
  faDown,
  faGear,
  faLockKeyhole,
  faCompass,
  faBedFront,
  faMemoPad,
  faMessages,
  faImage,
  faImages,
  faImagePolaroid,
  faTrash,
  faPen,
  faRotate,
  faPhoneFlip,
  faCircleExclamation,
  faCar,
  faCameraRetro,
  faBell,
  faTableColumns,
  faCircleUser,
  faUserGroup,
  faCrown,
  faCloudArrowUp,
  faLocationCrosshairs,
  faMapLocationDot,
  faLocationDot,
  faMagnifyingGlass,
  fasHeart,
  fasStar,
  faNoteSticky,
  faCopy,
  faHashtag,
  faCommentQuestion,
  faCalendar,
  faTreeCity
)

export default library
