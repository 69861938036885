import produce from 'immer'
import * as TYPES from '../types'

const initialState = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { gpid, status } = action.data ?? {}

  switch (action.type) {
    case TYPES.UPDATE_DESTINATION_STATUS:
      draft[gpid] = status
      break
  }
}, initialState)

// ------------------------------------
// Selectors
// ------------------------------------

const selectDestinationStatus = (state, gpid) => {
  return state[gpid] ?? {}
}

export const selectors = {
  selectDestinationStatus
}
