import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import AsyncSelect from 'react-select/async'
import IndicatorsContainer from './components/IndicatorsContainer'
import ValueContainer from './components/ValueContainer'
import SearchOption from './components/SearchOption'
import { mapIndexesToGroupedOptions } from './utils'
import { toKeyName } from '../../utils/isHotkey'
import { trackEvent } from '../../utils/tracking'
import styles from './Search.scss'

const components = {
  DropdownIndicator: null,
  ClearIndicator: null,
  IndicatorsContainer,
  ValueContainer,
  Option: SearchOption,
  LoadingMessage: () => null
}

const defaultState = {
  inputValue: '',
  selectedOption: null,
  menuIsOpen: false
}

// See: https://react-select.com/styles
const searchTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    ...styles
  }
})

class Search extends Component {
  static propTypes = {
    className: PropTypes.string,
    search: PropTypes.func.isRequired,
    user: PropTypes.object,
    defaultInputValue: PropTypes.string,
    placeholder: PropTypes.string,
    onSelectOption: PropTypes.func,
    onInputChange: PropTypes.func,
    onClear: PropTypes.func
  }

  static defaultProps = {
    search: () => new Promise((resolve) => resolve({})),
    onSelectOption: () => false,
    onInputChange: () => false,
    onClear: () => false,
    defaultInputValue: '',
    placeholder: ''
  }

  searchRef = React.createRef()
  state = {
    ...defaultState,
    inputValue: this.props.defaultInputValue
  }

  // Loads all of our search options asynchronously
  loadOptions = (inputValue) => {
    return this.props.search(inputValue)
      .then((searchResults) => {
        // Grouped options with headings
        const options = mapIndexesToGroupedOptions(searchResults, this.props.user)

        return options
      })
  }

  clear = () => {
    if (this.state.inputValue || this.state.selectedOption) {
      this.setState({ ...defaultState })
      this.props.onClear()
    }
  }

  focus = () => {
    this.searchRef.current.focus()
  }

  setSearchInput = (inputValue) => {
    this.setState({ inputValue })
  }

  handleKeyDown = (evt) => {
    switch (toKeyName(evt.key)) {
      case toKeyName('escape'):
        this.searchRef.current.blur()
        break
      default:
    }
  }

  handleFocus = () => {
    const { selectedOption } = this.state
    trackEvent('Global Search Focus')

    // On refocus, if there is a previously selected option,
    // we make sure that we use it to set the input value
    if (selectedOption && selectedOption.label) {
      this.setState({
        inputValue: selectedOption.label,
        selectedOption: null
      })
    }
  }

  handleSelectOption = (selectedOption) => {
    trackEvent('Global Search Select', {
      input: this.state.inputValue,
      selectedOption: selectedOption.label
    })

    this.setState({
      selectedOption,
      menuIsOpen: false
    })

    this.props.onSelectOption(selectedOption)
  }

  handleSelect = (selectedOption, { action }) => {
    switch (action) {
      case 'select-option':
        this.handleSelectOption(selectedOption)
        break
      case 'clear':
        this.clear()
        break
      default:
        break
    }
  }

  // Input value gets reset to empty string on any event that's not 'input-change'
  // We make sure that even on an options selection, we still treat it as an input.
  // When the user makes a new input change, we want to reset everything.
  handleInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'input-change':
        this.setState({
          inputValue,
          selectedOption: null,
          menuIsOpen: true
        })
        this.props.onInputChange(inputValue)
        break
      case 'set-value':
        this.setState({ inputValue })
        this.props.onInputChange(inputValue)
        break
      case 'menu-close':
        this.setState({ menuIsOpen: false })
        break
      default:
        break
    }
  }

  render () {
    const { selectedOption, inputValue, menuIsOpen } = this.state
    const controlShouldRenderValue = (!!selectedOption) || (inputValue.trim().length > 0)

    return (
      <AsyncSelect
        ref={this.searchRef}
        className={cx('searchbar-wrapper', this.props.className)}
        classNamePrefix='searchbar'
        components={components}
        noOptionsMessage={() => null}
        controlShouldRenderValue={controlShouldRenderValue}
        menuIsOpen={menuIsOpen}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
        loadOptions={this.loadOptions}
        onFocus={this.handleFocus}
        onInputChange={this.handleInputChange}
        inputValue={inputValue}
        onChange={this.handleSelect}
        value={selectedOption}
        theme={searchTheme}
        defaultOptions={false}
        cacheOptions={false}
        blurInputOnSelect />
    )
  }
}

export default Search
