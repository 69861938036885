import {
  UPDATE_USER,
  UPDATE_ACCOUNT_SETTINGS
} from 'client/shared/js/services/user/types'

const initialState = {}

const ACTION_HANDLERS = {
  [UPDATE_USER]: (state, action) => {
    const { settings } = action.data
    const accountSettings = settings?.account || {}

    return {
      ...state,
      ...accountSettings
    }
  },
  [UPDATE_ACCOUNT_SETTINGS]: (state, action) => {
    const settings = action.data

    return {
      ...state,
      ...settings
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
