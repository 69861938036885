import axios from 'axios'
import queryString from 'query-string'
import apiConfig from 'common/configs/api'
import { getHeaders } from '../auth/api'

const { apiUrl, announcements } = apiConfig
const announcementsUrl = `${apiUrl}${announcements.url}`

export const fetchAnnouncements = (days) => {
  const headers = getHeaders()
  const announcementsQuery = days ? `?${queryString.stringify({ days })}` : ''
  const url = `${announcementsUrl}${announcementsQuery}`

  return axios.get(url, { headers })
}

export default {
  fetchAnnouncements
}
