import ApiWrapper from './components/GoogleMapsApiWrapper'
import Marker from './components/MapMarker'
import GoogleMaps from './GoogleMaps'
import * as utils from './utils'

export const GoogleMapsApiWrapper = ApiWrapper
export const MapMarker = Marker
export const getUpdatedBounds = utils.getUpdatedBounds

// Standard default set of colors to use for lists until we can allow the user to select their own
export const MarkerColors = [{
  color: '#FF3B30', // red
  textColor: 'white'
}, {
  color: '#FF9500', // orange
  textColor: 'black'
}, {
  color: '#FFCC00', // yellow
  textColor: 'black'
}, {
  color: '#4CD964', // green
  textColor: 'black'
}, {
  color: '#007AFF', // blue
  textColor: 'white'
}, {
  color: '#5856D6', // purple
  textColor: 'white'
}, {
  color: '#FF2D55', // pink
  textColor: 'white'
}]

export default GoogleMaps
