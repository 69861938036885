import produce from 'immer'
import merge from 'lodash/merge'
import * as TYPES from '../types'

const initialState = {
  usernames: {}
}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  // const { gpid } = action.data?.result ?? {}
  const { placeStatus = {} } = action.data?.entities ?? {}

  switch (action.type) {
    case TYPES.UPDATE_PLACE_STATUS:
      merge(draft, placeStatus)
      break
  }
}, initialState)

// ------------------------------------
// Selectors
// ------------------------------------

const selectPlaceStatus = (state, userId) => {
  return state[userId]
}

export const selectors = {
  selectPlaceStatus
}
