import produce from 'immer'
import merge from 'lodash/merge'
import {
  FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS
} from 'client/app/js/services/boards/types'

export default produce((draft, action) => {
  const { boards } = action.data?.entities ?? []

  switch (action.type) {
    case FETCH_SUGGESTIONS_FOR_USER_AND_BOARD_SUCCESS:
      merge(draft, boards)
      break
  }
})
