import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, UncontrolledTooltip } from 'reactstrap'
import cx from 'classnames'
import nanoid from 'nanoid'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQuery from '../../hooks/useMediaQuery'
import styles from './FavoriteButton.module.scss'

const iconColorCombos = {
  'heart': styles.heartColor,
  'star': styles.starColor
}

const FavoriteButton = ({ id, className, placement, favorite, onFavorite, onUnfavorite, type, size, favoriteTooltip, unfavoriteTooltip }) => {
  const [isFavorited, setFavorited] = useState(favorite)
  const [favoritedDate, setFavoritedDate] = useState(moment().format())

  // Sync favorite props with isFavorited state after 5 seconds of inactivity
  // When we implement better optimistic UI logic, we can remove isFavorited state altogether
  if (moment().diff(favoritedDate, 's') > 5 && favorite !== isFavorited) {
    setFavorited(favorite)
  }

  const handleClick = (evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    if (isFavorited) {
      onUnfavorite()
    } else {
      onFavorite()
    }

    setFavorited(!isFavorited)
    setFavoritedDate(moment().format())
  }

  const btnId = id || nanoid()
  const faIconWeight = useMemo(() => (isFavorited) ? 'fas' : 'fal', [isFavorited])
  const iconStyle = useMemo(() => ({
    color: iconColorCombos[type],
    fontSize: size
  }), [type, size])

  const { isTablet } = useMediaQuery()

  const tooltip = useMemo(() => {
    // Disable tooptip on mobile
    if (isTablet) return null

    let tooltipText = null

    if (favoriteTooltip && isFavorited) {
      tooltipText = favoriteTooltip
    } else if (unfavoriteTooltip && !isFavorited) {
      tooltipText = unfavoriteTooltip
    }
    return (tooltipText) ? (
      <UncontrolledTooltip placement={placement} target={btnId}>
        {tooltipText}
      </UncontrolledTooltip>
    ) : null
  }, [btnId, placement, isFavorited, favoriteTooltip, unfavoriteTooltip, isTablet])

  return (
    <>
      <Button
        id={btnId}
        color='link'
        onClick={handleClick}
        className={cx(styles['favorite-btn'], 'transparent', className)}>
        <FontAwesomeIcon icon={[faIconWeight, type]} style={iconStyle} />
      </Button>
      {tooltip}
    </>
  )
}

FavoriteButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
  onFavorite: PropTypes.func,
  onUnfavorite: PropTypes.func,
  favoriteTooltip: PropTypes.string,
  unfavoriteTooltip: PropTypes.string,
  favorite: PropTypes.bool,
  type: PropTypes.oneOf(['heart', 'star']),
  size: PropTypes.string
}

FavoriteButton.defaultProps = {
  placement: 'top',
  onFavorite: () => false,
  onUnfavorite: () => false,
  favorite: false,
  type: 'heart',
  size: '24px'
}

export default FavoriteButton
