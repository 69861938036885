/**
 * Google Maps API wrapper that checks if google maps has already been loaded
 * Shows a loading indicator if not loaded before rendering the WrappedComponent
 * When loaded successfully, passes `google.maps` as a `maps` prop
 *
 * Docs: https://developers.google.com/maps/documentation/javascript/examples/
 */
import React, { Component } from 'react'
import GoogleMap from 'google-map-react'
import LoadingScreen from 'client/shared/js/components/LoadingScreen'
import { isCrawler } from 'client/shared/js/utils/meta'

const { googleMapLoader } = GoogleMap

export const bootstrapURLKeys = {
  key: __GOOGLE_MAPS_BROWSER_API_KEY__,
  libraries: ['places']
}

export const GoogleMapsApiWrapper = (WrappedComponent, options = {}) => {
  return class Wrapper extends Component {
    state = {
      maps: null,
      isCrawler: false
    }

    componentDidMount () {
      if (!isCrawler()) {
        googleMapLoader(bootstrapURLKeys)
          .then((maps) => {
            this.setState({ maps })
          })
      } else {
        this.setState({ isCrawler: true })
      }
    }

    render () {
      const LoadingContainer = options.LoadingContainer || LoadingScreen

      if (!this.state.maps && !this.state.isCrawler) {
        return <LoadingContainer />
      }

      return (
        <WrappedComponent {...this.props} maps={this.state.maps} />
      )
    }
  }
}

export default GoogleMapsApiWrapper
