import produce from 'immer'
import moment from 'moment'
import { GUEST_EDITING_BOARD } from '../../guest/types'

export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}

  switch (action.type) {
    case GUEST_EDITING_BOARD:
      // Reset the persist counter when users visit the guest board
      if (draft[bid]) {
        draft[bid]['__persisted_at'] = moment.now()
      }
      break
  }
})
