import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import styles from './CurrentLocationButton.module.scss'

const CurrentLocationButton = (props) => {
  return (
    <button className={cx(styles['current-location-btn'])} title='View your current location' {...props}>
      <FontAwesomeIcon icon={['fas', 'location-crosshairs']} />
    </button>
  )
}

export default CurrentLocationButton
