import produce from 'immer'
import merge from 'lodash/merge'
import differenceWith from 'lodash/differenceWith'
import {
  FETCH_BOARD_PHOTOS,
  DELETE_PHOTOS
} from 'client/app/js/services/boards/types'

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}
  const { boards } = action.data?.entities ?? {}

  switch (action.type) {
    case FETCH_BOARD_PHOTOS:
      merge(draft, boards)
      break

    case DELETE_PHOTOS:
      draft[bid].gallery = differenceWith(draft[bid].gallery, boards[bid].gallery, (photo1, photo2) => {
        return photo1.path === photo2.path
      })
      break
  }
})
