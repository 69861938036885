import produce from 'immer'
import merge from 'lodash/merge'
import forOwn from 'lodash/forOwn'
import * as TYPES from '../types'

const initialState = {
  usernames: {}
}

const mapUsernameToUserId = (draft, userStatus) => {
  forOwn(userStatus, ({ uid, usernameId }) => {
    if (uid && usernameId) {
      draft.usernames[usernameId] = { uid, usernameId }
    }
  })
}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  // const { uid } = action.data?.result ?? {}
  const { userStatus = {} } = action.data?.entities ?? {}

  switch (action.type) {
    case TYPES.UPDATE_USER_STATUS:
      mapUsernameToUserId(draft, userStatus)
      merge(draft, userStatus)
      break
  }
}, initialState)

// ------------------------------------
// Selectors
// ------------------------------------

const selectUserStatus = (state, userId) => {
  return state[userId]
}

const selectUserStatusByUsername = (state, usernameId) => {
  const { uid } = state.usernames?.[usernameId] ?? {}

  if (!uid) return {}

  return state[uid] ?? {}
}

export const selectors = {
  selectUserStatus,
  selectUserStatusByUsername
}
