import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BeatLoader from 'react-spinners/BeatLoader'

const ClearButton = ({ clearValue }) => (
  <a className='d-flex clear-btn' onClick={clearValue}>
    <FontAwesomeIcon icon={['fal', 'times']} />
  </a>
)

const LoadingIndicator = () => (
  <div className='searchbar__loading-indicator'>
    <BeatLoader color={'#FFD300'} size={8} margin={'1px'} />
  </div>
)

ClearButton.propTypes = {
  clearValue: PropTypes.func
}

// Replaces the current clear indicator which has weird rules
// This will show up if there is any input or selected value
const IndicatorsContainer = (props) => {
  const { selectProps, hasValue, clearValue } = props
  const asyncIndicator = (selectProps.isLoading)
    ? (<LoadingIndicator />)
    : null
  const clearIndicator = (selectProps.inputValue.length > 0 || hasValue)
    ? (<ClearButton clearValue={clearValue} />)
    : null

  return (
    <components.IndicatorsContainer {...props}>
      {asyncIndicator}
      {clearIndicator}
    </components.IndicatorsContainer>
  )
}

IndicatorsContainer.propTypes = {
  selectProps: PropTypes.object,
  hasValue: PropTypes.bool,
  clearValue: PropTypes.func
}

export default IndicatorsContainer
