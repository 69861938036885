import React from 'react'
import PropTypes from 'prop-types'
import UsernameTag from 'client/shared/js/components/UsernameTag'
import ProfilePhoto from 'client/shared/js/components/ProfilePhoto'
import PersonalityTypeBadge from 'client/shared/js/components/PersonalityTypeBadge'
import { getFullName } from 'client/shared/js/utils/stringFormatter'
import styles from './SearchOption.scss'

const UserOption = ({ user }) => {
  const { usernameId, username, photoUrl, firstName, lastName, personalityType } = user
  const fullName = getFullName(firstName, lastName)

  return (
    <div id={`user-search-option-${usernameId}`} className='user-search-option d-flex flex-row align-items-center justify-content-between'>
      <div className='d-flex flex-row'>
        <ProfilePhoto photoUrl={photoUrl} title={fullName} size={styles.badgeSize} />
        <div className='content-description'>
          <UsernameTag username={username} bold />
          <h6>{fullName}</h6>
        </div>
      </div>
      <PersonalityTypeBadge personalityType={personalityType} />
    </div>
  )
}

UserOption.defaultProps = {
  user: {}
}

UserOption.propTypes = {
  user: PropTypes.object
}

export default UserOption
