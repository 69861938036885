
import {
  FETCH_BOARDS_SUCCESS,
  CREATE_BOARD_SUCCESS,
  DELETE_BOARD_SUCCESS
} from '../../boards/types'
import { getDefaultBoardsOrder } from '../../boards/utils'

const initialState = {
  boardsOrder: []
}

const ACTION_HANDLERS = {
  [FETCH_BOARDS_SUCCESS]: (state, action) => {
    const { boards, boardsOrder } = action.data.entities
    const updatedOrder = boardsOrder || getDefaultBoardsOrder(boards)

    return {
      ...state,
      boardsOrder: updatedOrder
    }
  },
  [CREATE_BOARD_SUCCESS]: (state, action) => {
    const { bid } = action.data.result
    const board = action.data.entities.boards[bid]
    const boardsOrder = [{
      bid,
      updatedDate: board.meta.updatedDate
    }, ...state.boardsOrder]

    return {
      ...state,
      boardsOrder
    }
  },
  [DELETE_BOARD_SUCCESS]: (state, action) => {
    const { bid } = action.data.result
    let boardsOrder = []

    if (state.boardsOrder) {
      boardsOrder = state.boardsOrder.filter((currBoard) => {
        return bid !== currBoard.bid
      })
    }

    return {
      ...state,
      boardsOrder: [...boardsOrder]
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
