import produce from 'immer'
import {
  RESET_BROWSE_USER,
  FETCH_USER_FROM_USERNAME_SUCCESS,
  FETCH_USER_FROM_USERNAME_FAIL
} from './types'
import { getDefaultBoardsOrder } from '../../services/boards/utils'
import { SUCCESS, ERROR } from 'client/shared/js/enums/messageTypes'

const initialState = {
  uid: '',
  usernameId: '',
  username: '',
  profile: {},
  boards: null,
  totalBoards: 0,
  preferences: {},
  status: '',
  meta: {},
  settings: {
    account: {}
  },
  follows: {
    followers: {},
    following: {},
    followersOrder: [],
    followingOrder: [],
    totalFollowers: 0,
    totalFollowing: 0
  }
}

const ACTION_HANDLERS = {
  [RESET_BROWSE_USER]: (state, action) => {
    const username = action.data || ''

    return {
      ...initialState,
      username
    }
  },
  [FETCH_USER_FROM_USERNAME_SUCCESS]: produce((draft, action) => {
    const { uid } = action.data.result ?? {}
    const { users, boards } = action.data.entities ?? {}
    const boardsOrder = getDefaultBoardsOrder(boards)

    return {
      ...draft,
      ...users[uid],
      status: SUCCESS,
      preferences: {
        ...draft.preferences,
        boardsOrder
      }
    }
  }),
  [FETCH_USER_FROM_USERNAME_FAIL]: (state, action) => {
    return {
      ...state,
      status: ERROR
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
