import {
  SEND_PASSWORD_RESET,
  GET_USER_TOKEN_SUCCESS,
  INVALID_TOKEN
} from './types'
import { API_ERROR, MALFORMED_ERROR } from '../globalMessage/types'
import firebaseUtils, { providers } from 'client/shared/js/utils/firebase'
import authApi from './api'

export const logout = () => {
  return (dispatch) => {
    return authApi.logout()
  }
}

/**
 * Checks token state and makes api request
 *  If valid: auto refresh token and continue with request
 *  If invalid: return notification error
 */
export const checkAndRefreshUserToken = () => {
  return (dispatch) => {
    return authApi.getAndSetUserToken()
      .then((token) => {
        if (token) {
          dispatch(getUserTokenSuccess(token))
        } else {
          dispatch(invalidToken())
        }
      })
  }
}

export const getUserTokenSuccess = (token) => ({
  type: GET_USER_TOKEN_SUCCESS,
  data: {
    token
  }
})

export const invalidToken = () => ({
  type: INVALID_TOKEN
})

export const sendPasswordResetEmail = (email) => {
  return (dispatch) => {
    const updatedEmail = email.trim()

    if (updatedEmail.length === 0) {
      dispatch({
        type: MALFORMED_ERROR,
        message: 'Please input a valid email address'
      })
    } else {
      return firebaseUtils.sendPasswordResetEmail(updatedEmail)
        .then((res) => {
          dispatch({
            type: SEND_PASSWORD_RESET,
            result: res,
            track: {
              event: {
                name: 'Password Reset Request'
              }
            }
          })
        })
        .catch((res) => {
          dispatch({
            type: API_ERROR
          })
        })
    }
  }
}

/**
 * Reauthenticates for security sensitive actions
 */
export const reauthenticate = (email = '', password = '') => {
  return (dispatch) => {
    const user = firebaseUtils.getCurrentUser()

    if (user) {
      const credential = providers.email.credential(email, password)

      return user.reauthenticateWithCredential(credential)
        .then(() => user)
    } else {
      return new Promise((resolve, reject) => {
        reject(new Error('Current user is not valid'))
      })
    }
  }
}
