// Firebase Web Client SDK
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
const firebaseApp = firebase.initializeApp(__FIREBASE_CONFIG__)

export const auth = firebaseApp.auth()

export const providers = {
  facebook: firebase.auth.FacebookAuthProvider,
  google: firebase.auth.GoogleAuthProvider,
  email: firebase.auth.EmailAuthProvider
}

export const OAUTH_REDIRECT_PROVIDER_KEY = 'OAuth redirect provider'

export const createUserWithEmailAndPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password)
}

export const signInWithCustomToken = (token) => {
  return auth.signInWithCustomToken(token)
}

export const signInWithEmailAndPassword = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password)
}

export const signInWithGooglePopup = () => {
  return auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

export const signInWithFacebookPopup = () => {
  return auth.signInWithPopup(new firebase.auth.FacebookAuthProvider())
}

const _setRedirectStatus = (provider) => {
  window.sessionStorage.setItem(OAUTH_REDIRECT_PROVIDER_KEY, provider)
}

export const signInWithGoogleRedirect = () => {
  _setRedirectStatus('google')
  return auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider())
}

export const signInWithFacebookRedirect = () => {
  _setRedirectStatus('facebook')
  return auth.signInWithRedirect(new firebase.auth.FacebookAuthProvider())
}

export const getRedirectResult = () => {
  return auth.getRedirectResult()
}

export const logout = () => {
  return auth.signOut()
}

export const sendPasswordResetEmail = (email) => {
  return auth.sendPasswordResetEmail(email)
}

export const getCurrentUser = () => {
  return auth.currentUser || null
}

export const getIdToken = (forceRefresh = true) => {
  const user = getCurrentUser()

  if (user) {
    return user.getIdToken(forceRefresh)
      .then((token) => {
        return token
      })
  } else {
    return new Promise((resolve, reject) => {
      resolve(null)
    })
  }
}

export default {
  signInWithCustomToken,
  signInWithEmailAndPassword,
  logout,
  sendPasswordResetEmail,
  getCurrentUser,
  getIdToken
}
