export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS'
export const TRACK_USER = 'TRACK_USER'

export const FOLLOW_USER = 'FOLLOW_USER'
export const UNFOLLOW_USER = 'UNFOLLOW_USER'
export const APPROVE_USER_FOLLOW = 'APPROVE_USER_FOLLOW'
export const DELETE_USER_FOLLOW = 'DELETE_USER_FOLLOW'

export const GET_STREAM_TOKENS = 'GET_STREAM_TOKENS'
export const FETCH_NOTIFICATION_FEED = 'FETCH_NOTIFICATION_FEED'
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS'
export const REMOVE_NOTIFICATION_FEED_ITEM = 'REMOVE_NOTIFICATION_FEED_ITEM'
export const REMOVE_NOTIFICATION_FEED_GROUP = 'REMOVE_NOTIFICATION_FEED_GROUP'
