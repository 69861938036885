export const SIGHT_SEEKER = 'SIGHT_SEEKER'
export const ALL_AROUND = 'ALL_AROUND'
export const CHILLEST = 'CHILLEST'
export const URBAN = 'URBAN'
export const TRADITIONALIST = 'TRADITIONALIST'
export const LOCAL = 'LOCAL'
export const TRAIL_TREKKER = 'TRAIL_TREKKER'

export default {
  SIGHT_SEEKER,
  ALL_AROUND,
  CHILLEST,
  URBAN,
  TRADITIONALIST,
  LOCAL,
  TRAIL_TREKKER
}
