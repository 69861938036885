import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { BOARDS, USERS, DESTINATIONS } from '../../enums/indexNames'
import BoardOption from './BoardOption'
import UserOption from './UserOption'
import DestinationOption from './DestinationOption'
import './SearchOption.scss'

const onClick = (evt) => {
  evt.preventDefault()
}

const renderSearchOptionItem = (indexName, data) => {
  switch (indexName) {
    case BOARDS:
      return (<BoardOption board={data} />)
    case USERS:
      return (<UserOption user={data} />)
    case DESTINATIONS:
      return (<DestinationOption destination={data} />)
    default:
      return null
  }
}

const SearchOption = (props) => {
  const { url = '/', data } = props.data // Get the search option data obj
  const { _index, _source } = data
  let SearchOptionItem = renderSearchOptionItem(_index, _source)

  return (
    <a href={url} onClick={onClick}>
      <components.Option {...props}>
        {SearchOptionItem}
      </components.Option>
    </a>
  )
}

SearchOption.propTypes = {
  data: PropTypes.object
}

export default SearchOption
