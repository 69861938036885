import produce from 'immer'
import moment from 'moment'
import {
  FETCH_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_DETAILS_SUCCESS
} from 'client/app/js/services/boards/types'

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}

  switch (action.type) {
    case FETCH_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_DETAILS_SUCCESS:
      draft[bid] = moment().format()
      break
  }
}, {})

// ------------------------------------
// Selectors
// ------------------------------------

const selectBoardStatus = (state, boardId) => {
  return state[boardId]
}

export const selectors = {
  selectBoardStatus
}
