import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import isFunction from 'lodash/isFunction'
import { isHotkey } from 'client/shared/js/utils/isHotkey'
import { formatPrediction, formatAddressComponents } from '../../utils/googlePlacesFormatter'
import { trackEvent } from '../../utils/tracking'
import './GooglePlacesAutoComplete.scss'

const defaultPlace = {
  name: '',
  gpid: '',
  location: {}
}

const PLACE_TYPES = {
  ALL: 'all',
  CITY: 'city'
}

class GooglePlacesAutoComplete extends Component {
  static propTypes = {
    id: PropTypes.string,
    maps: PropTypes.object.isRequired,
    place: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onInputChange: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    alwaysRenderSuggestions: PropTypes.bool,
    highlightFirstSuggestion: PropTypes.bool,
    focusInputOnSuggestionClick: PropTypes.bool,
    renderInputComponent: PropTypes.func,
    latlng: PropTypes.object,
    registerHotkeys: PropTypes.object,
    ignorePlaceIds: PropTypes.array,
    disableAutoFocus: PropTypes.bool,
    disableAttribution: PropTypes.bool,
    type: PropTypes.oneOf([PLACE_TYPES.ALL, PLACE_TYPES.CITY])
  }

  static defaultProps = {
    place: { ...defaultPlace },
    placeholder: 'Add a place',
    registerHotkeys: {},
    ignorePlaceIds: [],
    disableAutoFocus: false,
    alwaysRenderSuggestions: false,
    highlightFirstSuggestion: false,
    focusInputOnSuggestionClick: false,
    disableAttribution: false,
    onInputChange: () => false,
    onSuggestionSelected: () => false,
    onEnter: () => false,
    onFocus: () => false,
    onBlur: () => false,
    type: PLACE_TYPES.ALL
  }

  state = {
    suggestions: [],
    place: {
      ...defaultPlace,
      name: this.props.place.location?.displayText || '',
      ...this.props.place
    }
  }

  _isMounted = false
  input = null

  componentDidMount () {
    const { maps } = this.props

    this.sessionToken = new maps.places.AutocompleteSessionToken()
    this.autocomplete = new maps.places.AutocompleteService()
    this.geocoder = new maps.Geocoder()
    this.statusOk = maps.places.PlacesServiceStatus.OK
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  get = () => {
    return this.state.place
  }

  focus = () => {
    if (this.input) {
      this.input.focus()
    }
  }

  // https://github.com/moroshko/react-autosuggest/issues/469#issuecomment-342368324
  storeInputReference = (autosuggest) => {
    if (autosuggest != null) {
      this.input = autosuggest.input
    }
  }

  handleInputChange = (evt, { newValue, method }) => {
    // Reset placeId and location if the user types something new
    if (method === 'type') {
      const updatedPlace = {
        ...defaultPlace,
        name: newValue
      }

      this.setState({
        place: updatedPlace
      })
      this.props.onInputChange(updatedPlace)
    } else if (method === 'escape') {
      this.onSuggestionsClearRequested()
      this.handleBlur()
    }
  }

  handleBlur = (evt) => {
    this.props.onBlur(this.state.place)
  }

  handleKeyDown = (evt) => {
    const registerHotkeys = {
      ...this.props.registerHotkeys,
      ...(this.props.onEnter ? { 'enter': this.props.onEnter } : {})
    }

    // Registers all of our hotkeys and performs the associated action
    Object.keys(registerHotkeys).forEach((command) => {
      const action = registerHotkeys[command]
      const isCommandHotkey = isHotkey(command)

      if (isCommandHotkey(evt) && isFunction(action)) {
        evt.preventDefault()
        action.call(this, this.state.place)
      }
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.fetchPredictions(value)
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  getDisplayName = (place) => {
    let name
    switch (this.props.type) {
      case PLACE_TYPES.CITY:
        name = place.detailedName
        break
      case PLACE_TYPES.ALL:
      default:
        name = place.name
    }

    return name
  }

  // This triggers anytime a selection is selected or keyboard highlighted
  getSuggestionValue = (suggestion) => {
    const place = formatPrediction(suggestion)
    const updatedPlace = {
      gpid: place.gpid,
      name: this.getDisplayName(place)
    }

    trackEvent('Google Place Autocomplete Selection', {
      place: this.getDisplayName(place)
    })

    this.setState({
      place: updatedPlace
    })
    this.props.onInputChange(updatedPlace)

    return place
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const place = formatPrediction(suggestion)

    // Gets latlng of selection
    this.geocoder.geocode({
      'placeId': place.gpid
    }, (res, status) => {
      let location = {}

      if (status === this.props.maps.GeocoderStatus.OK) {
        const currLocation = res[0].geometry.location
        const addressComponents = formatAddressComponents(res[0].address_components)
        const lat = currLocation.lat()
        const lng = currLocation.lng()

        location = {
          ...addressComponents,
          displayText: place.detailedName,
          latlng: {
            lat,
            lng
          }
        }
      }

      const updatedPlace = {
        name: this.getDisplayName(place),
        gpid: place.gpid,
        location
      }

      // Reset session token when something is selected
      this.sessionToken = new this.props.maps.places.AutocompleteSessionToken()
      this.props.onSuggestionSelected(updatedPlace)

      if (this._isMounted) {
        this.setState({
          place: updatedPlace
        })
      }
    })
  }

  // Prevents rendering suggestions when all the conditions are met:
  //  1. There is already a place id
  //  2. The current input string is the same as the last name saved
  //  3. The number of available suggestions is less than or equal to 1
  shouldRenderSuggestions = (value) => {
    if (this.props.place.gpid &&
      this.props.place.name === value &&
      this.state.suggestions.length <= 1) {
      return false
    }

    return value.trim().length > 0
  }

  autoCompleteCallback = (predictions, status) => {
    if (status !== this.statusOk) {
      this.onSuggestionsClearRequested()
    } else {
      const suggestions = (this.props.ignorePlaceIds.length > 0) ? predictions.filter((prediction) => {
        return (this.props.ignorePlaceIds.indexOf(prediction.place_id) === -1)
      }) : predictions

      this.setState({ suggestions })
    }
  }

  fetchPredictions = (input) => {
    let predictionOptions = {
      input,
      sessionToken: this.sessionToken
    }

    if (this.props.latlng) {
      const { maps, latlng } = this.props

      predictionOptions.location = new maps.LatLng(latlng.lat, latlng.lng)
      predictionOptions.radius = 40000 // 41000 meters ~ 25 miles
    }

    if (this.props.type === PLACE_TYPES.CITY) {
      predictionOptions.types = ['(cities)']
    }

    if (this.autocomplete && input) {
      this.autocomplete.getPlacePredictions(predictionOptions, this.autoCompleteCallback)
    }
  }

  renderPlaceSuggestion = (suggestion) => {
    const place = formatPrediction(suggestion)

    return (
      <div>
        <b>{place.name}</b> {place.secondaryText}
      </div>
    )
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const attribution = (!this.props.disableAttribution) ? (
      <div className='attribution-logo'>
        <img src='/static/assets/img/logos/google/powered-by-google.png' width={144} />
      </div>
    ) : null

    return (
      <div {...containerProps}>
        {children}
        {attribution}
      </div>
    )
  }

  render () {
    const name = this.state.place.name
    const suggestions = this.state.suggestions
    const inputProps = {
      id: this.props.id,
      type: 'search', // required to fix 1password issues
      placeholder: this.props.placeholder,
      value: name,
      onChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      onFocus: this.props.onFocus,
      onBlur: this.handleBlur,
      autoFocus: !this.props.disableAutoFocus
    }

    const autoSuggestProps = {
      suggestions: suggestions,
      getSuggestionValue: this.getSuggestionValue,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      renderSuggestionsContainer: this.renderSuggestionsContainer,
      renderSuggestion: this.renderPlaceSuggestion,
      shouldRenderSuggestions: this.shouldRenderSuggestions,
      alwaysRenderSuggestions: this.props.alwaysRenderSuggestions,
      highlightFirstSuggestion: this.props.highlightFirstSuggestion,
      focusInputOnSuggestionClick: this.props.focusInputOnSuggestionClick,
      ref: this.storeInputReference,
      inputProps
    }

    return (
      <Autosuggest {...autoSuggestProps} />
    )
  }
}

export default GooglePlacesAutoComplete
