import { combineReducers } from 'redux'
import { bindSelectors } from 'client/shared/js/utils/selector'
import { UPDATE_USER } from 'client/shared/js/services/user/types'
import boardsReducer from './boards'
import preferencesReducer from './preferences'
import settingsReducer from './settings'
import profileReducer, { selectors as profileSelectors } from './profile'
import featureFlagsReducer from './featureFlags'
import followsReducer from './follows'
import feedsReducer from './feeds'

// ------------------------------------
// Reducer
// ------------------------------------
// Sets the root user attribute state value for the provided actions
const userAttrReducer = (attr, initialAttrState) => {
  // Expects the data fields to be in the following format:
  //  const { id, email, usernameId, ... } = action.data
  return (state = initialAttrState, action) => {
    switch (action.type) {
      case UPDATE_USER:
        const data = action.data
        return data[attr] || state
      default:
    }

    return state
  }
}

export default combineReducers({
  uid: userAttrReducer('uid', ''),
  email: userAttrReducer('email', ''),
  usernameId: userAttrReducer('usernameId', ''),
  meta: userAttrReducer('meta', {}),
  totalBoards: userAttrReducer('totalBoards', 0),
  boards: boardsReducer,
  profile: profileReducer,
  preferences: preferencesReducer,
  featureFlags: featureFlagsReducer,
  settings: settingsReducer,
  follows: followsReducer,
  feeds: feedsReducer
  // pins: {} // The places pinned for later
})

// ------------------------------------
// Selectors
// ------------------------------------

const selectUserBoards = (state) => {
  return state.boards
}

const selectUserProfile = (state) => {
  return state.profile
}

const selectUserPreferences = (state) => {
  return state.preferences
}

export const selectors = {
  selectUserBoards,
  selectUserProfile,
  selectUserPreferences,
  ...bindSelectors(selectUserProfile, profileSelectors)
}
