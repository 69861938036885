import produce from 'immer'
import {
  FETCH_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_SUCCESS
} from 'client/app/js/services/boards/types'
import {
  COMMIT_ADD_PLACE,
  COMMIT_UPDATE_PLACE,
  PREEMPT_UPDATE_PLACE_ERROR,
  UPDATE_PLACE_SUCCESS,
  UPDATE_PLACE_ERROR,
  COMMIT_DELETE_PLACE
} from 'client/app/js/services/listPlaces/types'
import { CLEAR_PLACE_STATUS } from '../types'

const initialStatus = {
  status: 'success',
  sent: null,
  updatedPlace: null,
  error: null,
  code: ''
}

const initializeStatus = (draft, listPlaceId) => {
  draft[listPlaceId] = { ...initialStatus }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { lpid } = action.data?.result ?? {}
  const { listPlaces = {}, listPlaceStatus = {} } = action.data?.entities ?? {}
  const error = action.error ?? {}

  switch (action.type) {
    case FETCH_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_SUCCESS:
      for (const lpid of Object.keys(listPlaces)) {
        initializeStatus(draft, lpid)
      }
      break

    case CLEAR_PLACE_STATUS:
    case COMMIT_ADD_PLACE:
      initializeStatus(draft, lpid)
      break

    case COMMIT_UPDATE_PLACE:
      draft[lpid] = {
        status: 'requesting',
        sent: listPlaces[lpid],
        updatedPlace: null,
        error: null,
        code: ''
      }
      break

    case UPDATE_PLACE_SUCCESS:
      draft[lpid] = {
        status: 'success',
        sent: null,
        updatedPlace: listPlaces[lpid],
        error: null,
        code: ''
      }
      break

    case PREEMPT_UPDATE_PLACE_ERROR:
    case UPDATE_PLACE_ERROR:
      Object.assign(draft[lpid], {
        status: 'error',
        updatedPlace: null,
        error: error ?? null,
        code: error?.type ?? ''
      }, listPlaceStatus[lpid])
      break

    case COMMIT_DELETE_PLACE:
      delete draft[lpid]
      break
  }
}, {})

// ------------------------------------
// Selectors
// ------------------------------------

const selectListPlaceStatus = (state, listPlaceId) => {
  return state[listPlaceId]
}

export const selectors = {
  selectListPlaceStatus
}
