import {
  UPDATE_USER,
  FOLLOW_USER,
  UNFOLLOW_USER,
  APPROVE_USER_FOLLOW,
  DELETE_USER_FOLLOW
} from 'client/shared/js/services/user/types'

const initialState = {
  followers: {},
  following: {},
  followersOrder: [],
  followingOrder: []
}

const ACTION_HANDLERS = {
  [UPDATE_USER]: (state, action) => {
    const follows = action.data.follows || {}

    return {
      ...state,
      ...follows
    }
  },
  [FOLLOW_USER]: (state, action) => {
    const { user } = action.data
    const followingOrder = [user.uid, ...state.followingOrder]
    const following = {
      ...state.following,
      [user.uid]: action.data
    }

    return {
      ...state,
      following,
      followingOrder
    }
  },
  [UNFOLLOW_USER]: (state, action) => {
    const { followUid } = action.data
    const following = { ...state.following }
    delete following[followUid]

    let followingOrder = [...state.followingOrder]
    followingOrder.splice(followingOrder.indexOf(followUid), 1)

    return {
      ...state,
      following,
      followingOrder
    }
  },
  [APPROVE_USER_FOLLOW]: (state, action) => {
    const { user } = action.data
    const followers = {
      ...state.followers,
      [user.uid]: action.data
    }

    return {
      ...state,
      followers
    }
  },
  [DELETE_USER_FOLLOW]: (state, action) => {
    const { uid } = action.data
    const followers = { ...state.followers }
    delete followers[uid]

    let followersOrder = [...state.followersOrder]
    followersOrder.splice(followersOrder.indexOf(uid), 1)

    return {
      ...state,
      followers,
      followersOrder
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
