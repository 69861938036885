
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  API_ERROR,
  AUTH_ERROR,
  MALFORMED_ERROR,
  FILE_UPLOAD_ERROR
} from './types'
import {
  INVALID_CREDENTIAL,
  WRONG_PASSWORD
} from '../../enums/authStatus'
import { SUCCESS, ERROR } from 'client/shared/js/enums/messageTypes'

const DEFAULT_ERROR_MSG = 'Whoops, looks like something went wrong.'
const CONTACT_MSG = 'Please try again or contact us at support@hipherd.com if you continue to run into issues.'

const initialState = {
  type: '',
  message: ''
}

const genericError = (state, action) => {
  return {
    type: ERROR,
    message: (action.message)
      ? `${action.message} ${CONTACT_MSG}`
      : `${DEFAULT_ERROR_MSG} ${CONTACT_MSG}`
  }
}

const authError = (state, action) => {
  if (action.error) {
    const code = action.error.code || ''

    switch (code) {
      case WRONG_PASSWORD:
        return {
          type: ERROR,
          message: 'Your current password is incorrect. Please try again.'
        }
      case INVALID_CREDENTIAL:
        return {
          type: ERROR,
          message: 'We could not update your password. Please refresh the page and try again.'
        }
      default:
        break
    }
  }

  return genericError(state, action)
}

export default (state = initialState, action) => {
  switch (action.type) {
    case API_ERROR:
    case MALFORMED_ERROR:
    case FILE_UPLOAD_ERROR:
      return genericError(state, action)
    case AUTH_ERROR:
      return authError(state, action)
    case ERROR_MESSAGE:
      return action.message ? ({
        type: ERROR,
        message: action.message
      }) : (
        genericError(state, action)
      )
    case SUCCESS_MESSAGE:
      return {
        type: SUCCESS,
        message: action.message || ''
      }
    default:
      return {
        type: '',
        message: ''
      }
  }
}
