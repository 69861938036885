import React from 'react'
import PropTypes from 'prop-types'
import PersonalitiesConfig from 'common/configs/personalities'
import './PersonalityTypeBadge.scss'

const PersonalityTypeBadge = ({ personalityType, size }) => {
  const personalityConfig = (personalityType) ? PersonalitiesConfig[personalityType.toUpperCase()] : {}
  const bgColor = {
    backgroundColor: personalityConfig.color
  }

  return (personalityConfig.imgSrc) ? (
    <div className='personality-type-badge' title={personalityType} style={bgColor}>
      <img src={personalityConfig.imgSrc} width={size} height={size} />
    </div>
  ) : null
}

PersonalityTypeBadge.defaultProps = {
  size: '24px'
}

PersonalityTypeBadge.propTypes = {
  personalityType: PropTypes.string,
  size: PropTypes.string
}

export default PersonalityTypeBadge
