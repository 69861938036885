import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import CoverImage from 'client/shared/js/components/CoverImage'

const UnsplashBackground = ({
  backgroundPhoto,
  horizontalPosition,
  verticalPosition,
  resolution,
  disableLinks,
  disableAttribution,
  backgroundFilter,
  hoverEffect
}) => {
  const backgroundImageUrl = useMemo(() => {
    const { urls } = backgroundPhoto

    return (urls) ? (urls[resolution] || urls.regular) : null
  }, [backgroundPhoto, resolution])

  const attribution = useMemo(() => {
    const { user } = backgroundPhoto

    if (!user || disableAttribution) {
      return null
    }
    const unsplashUrl = user.url || `https://unsplash.com/@${user.username}`
    const name = user.name || user.username
    const userUrl = `${unsplashUrl}?utm_source=HipHerd&utm_medium=referral`

    return (disableLinks) ? (
      <h6 className='m-2 text-white'>Photo by <u>{name}</u> on <u>Unsplash</u></h6>
    ) : (
      <h6 className='m-2 text-white'>
        Photo by <a href={userUrl} target='_blank' rel='noopener'>{name}</a> on <a href='https://unsplash.com' target='_blank'>Unsplash</a>
      </h6>
    )
  }, [backgroundPhoto, disableAttribution, disableLinks])

  const innerClass = useMemo(() => {
    return (attribution) ? cx('d-flex', {
      [`justify-content-${horizontalPosition}`]: true,
      [`align-items-${verticalPosition}`]: true
    }) : ''
  }, [attribution, horizontalPosition, verticalPosition])

  return (backgroundImageUrl) ? (
    <CoverImage
      innerClass={innerClass}
      photoUrl={backgroundImageUrl}
      backgroundFilter={backgroundFilter}
      hoverReveal={hoverEffect}>
      {attribution}
    </CoverImage>
  ) : null
}

UnsplashBackground.propTypes = {
  backgroundPhoto: PropTypes.object,
  horizontalPosition: PropTypes.string,
  verticalPosition: PropTypes.string,
  resolution: PropTypes.string,
  disableLinks: PropTypes.bool,
  disableAttribution: PropTypes.bool,
  backgroundFilter: PropTypes.oneOf(['top', 'bottom', 'full']),
  hoverEffect: PropTypes.bool
}

UnsplashBackground.defaultProps = {
  backgroundPhoto: {},
  horizontalPosition: 'center',
  verticalPosition: 'center',
  resolution: 'custom',
  disableLinks: false,
  disableAttribution: false,
  hoverEffect: false
}

export default UnsplashBackground
