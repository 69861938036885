import produce from 'immer'
import moment from 'moment'
import assign from 'lodash/assign'
import { merge } from 'client/shared/js/utils/object'
import { pipeReducers } from 'client/shared/js/utils/reducer'
import currentBoardReducer from '../boards/reducer/currentBoard'
import {
  CREATE_LIST,
  COMMIT_UPDATE_LIST,
  UPDATE_LIST_SUCCESS,
  COMMIT_DELETE_LIST,
  COMMIT_REORDER_PLACES_WITHIN_LIST,
  COMMIT_REORDER_PLACES_BETWEEN_LISTS
} from './types'
import {
  FETCH_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_DETAILS_SUCCESS,
  COLLABORATOR_UPDATE_LIST
} from '../boards/types'
import {
  COMMIT_ADD_PLACE,
  COMMIT_DELETE_PLACE
} from '../listPlaces/types'
import {
  GUEST_CREATE_BOARD,
  GUEST_EDITING_BOARD,
  GUEST_CREATE_LIST,
  GUEST_UPDATE_LIST,
  GUEST_REORDER_PLACES_WITHIN_LIST,
  GUEST_REORDER_PLACES_BETWEEN_LISTS,
  GUEST_DELETE_LIST,
  GUEST_ADD_PLACE,
  GUEST_DELETE_PLACE
} from '../guest/types'

const initialState = {
  currentBoard: null
}

// ------------------------------------
// Reducer
// ------------------------------------
export default pipeReducers(
  produce((draft, action) => {
    const { bid, lid, lpid } = action.data?.result ?? {}
    const { lists } = action.data?.entities ?? {}
    const currBid = draft.currentBoard

    switch (action.type) {
      case FETCH_BOARD_SUCCESS:
      case FETCH_PUBLIC_BOARD_SUCCESS:
      case FETCH_PUBLIC_BOARD_DETAILS_SUCCESS:
        draft[bid] = draft[bid] ?? {}
        merge(draft[bid], lists)
        break

      case GUEST_CREATE_BOARD:
        draft[bid] = lists
        break

      case CREATE_LIST:
      case COMMIT_UPDATE_LIST:
      case COLLABORATOR_UPDATE_LIST:
      case UPDATE_LIST_SUCCESS:
      case COMMIT_ADD_PLACE:
      case COMMIT_REORDER_PLACES_WITHIN_LIST:
      case GUEST_CREATE_LIST:
      case GUEST_UPDATE_LIST:
      case GUEST_ADD_PLACE:
      case GUEST_REORDER_PLACES_WITHIN_LIST:
        draft[currBid][lid] = draft[currBid][lid] ?? {}
        assign(draft[currBid][lid], lists[lid])
        break

      case COMMIT_REORDER_PLACES_BETWEEN_LISTS:
      case GUEST_REORDER_PLACES_BETWEEN_LISTS:
        const { source, destination } = action.data.result
        assign(draft[currBid][source], lists[source])
        draft[currBid][destination] = draft[currBid][destination] ?? {}
        assign(draft[currBid][destination], lists[destination])
        break

      case COMMIT_DELETE_LIST:
      case GUEST_DELETE_LIST:
        delete draft[currBid][lid]
        break

      case COMMIT_DELETE_PLACE:
      case GUEST_DELETE_PLACE:
        const index = draft[currBid][lid]?.placesOrder?.indexOf(lpid)
        if (index > -1) draft[currBid][lid].placesOrder.splice(index, 1)
        break

      case GUEST_EDITING_BOARD:
        // Reset the persist counter when users visit the guest board
        if (draft[bid]) {
          draft[bid]['__persisted_at'] = moment.now()
        }
        break
    }
  }, initialState),
  currentBoardReducer
)

// ------------------------------------
// Selectors
// ------------------------------------
/**
 * Select the list with specified boardId and listId
 * @param {Object} state The redux store state
 * @param {String} boardId The board id, defaults to current board id
 * @param {String} listId The list id
 */
const selectBoardList = (state, boardId = null, listId) => {
  const bid = boardId ?? state.currentBoard

  return state[bid]?.[listId] ?? {}
}

export const selectors = {
  selectBoardList
}
