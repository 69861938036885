import produce from 'immer'
import {
  FETCH_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_SUCCESS,
  CREATE_LIST
} from 'client/app/js/services/boards/types'
import {
  COMMIT_UPDATE_LIST,
  PREEMPT_UPDATE_LIST_ERROR,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_ERROR,
  COMMIT_DELETE_LIST,
  COMMIT_REORDER_PLACES_BETWEEN_LISTS
} from 'client/app/js/services/lists/types'
import { CLEAR_LIST_STATUS } from '../types'

const initialStatus = {
  status: 'success',
  updatedList: null,
  sent: null,
  error: null,
  code: ''
}

const initializeStatus = (draft, listId) => {
  draft[listId] = { ...initialStatus }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { lid } = action.data?.result ?? {}
  const { lists = {}, listStatus = {} } = action.data?.entities ?? {}
  const error = action.error ?? {}

  switch (action.type) {
    case FETCH_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_SUCCESS:
    case COMMIT_REORDER_PLACES_BETWEEN_LISTS:
      for (const lid of Object.keys(lists)) {
        initializeStatus(draft, lid)
      }
      break

    case CLEAR_LIST_STATUS:
    case CREATE_LIST:
      initializeStatus(draft, lid)
      break

    case COMMIT_UPDATE_LIST:
      draft[lid] = {
        status: 'requesting',
        sent: lists[lid],
        updatedList: null,
        error: null,
        code: ''
      }
      break

    case UPDATE_LIST_SUCCESS:
      draft[lid] = {
        status: 'success',
        sent: null,
        updatedList: lists[lid],
        error: null,
        code: ''
      }
      break

    case PREEMPT_UPDATE_LIST_ERROR:
    case UPDATE_LIST_ERROR:
      Object.assign(draft[lid], {
        status: 'error',
        updatedList: null,
        error: error ?? null,
        code: error?.type ?? ''
      }, listStatus[lid])
      break

    case COMMIT_DELETE_LIST:
      delete draft[lid]
      break
  }
}, {})

// ------------------------------------
// Selectors
// ------------------------------------

const selectListStatus = (state, listId) => {
  return state[listId]
}

export const selectors = {
  selectListStatus
}
