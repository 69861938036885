export const SUCCESS = 'success'
export const INFO = 'info'
export const WARNING = 'warning'
export const ERROR = 'error'

export default {
  SUCCESS,
  INFO,
  WARNING,
  ERROR
}
