import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'
import nanoid from 'nanoid'
import './PrivateIndicator.scss'

const PrivateIndicator = (props) => {
  const id = props.id || nanoid()
  const indicatorClass = classNames('private-indicator', props.className, {
    badge: props.badge
  })

  return useMemo(() => (
    <>
      <span id={id} className={indicatorClass}>
        <FontAwesomeIcon icon='lock-keyhole' className={props.iconClassname} />
      </span>
      { props.children ? (
        <UncontrolledTooltip placement={props.placement} target={id}>
          {props.children}
        </UncontrolledTooltip>
      ) : null }
    </>
  ), [props, id, indicatorClass])
}

PrivateIndicator.propTypes = {
  id: PropTypes.string,
  badge: PropTypes.bool,
  placement: PropTypes.string,
  className: PropTypes.string,
  iconClassname: PropTypes.string,
  children: PropTypes.node
}

PrivateIndicator.defaultProps = {
  badge: false,
  placement: 'top',
  className: '',
  iconClassname: ''
}

export default PrivateIndicator
