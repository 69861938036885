import Loadable from 'client/shared/js/components/Loadable'
import RouteMap from 'common/configs/routeMap'

const boardRoutes = RouteMap.board
const boardUrl = boardRoutes.url
const boardIdPath = `${boardUrl}/:boardId`

export const newBoardPath = `${boardUrl}${boardRoutes.new}`
export const guestBoardPath = `${boardUrl}${boardRoutes.guest}`
export const guestBoardDetailsPath = `${guestBoardPath}${boardRoutes.public.url}/:boardSharedBoardId?`
export const guestBoardPlaceDetailsPath = `${guestBoardPath}${boardRoutes.public.url}/:boardSharedBoardId?${boardRoutes.public.listPlace}/:listPlaceId?`
export const guestPlaceDetailsPath = `${guestBoardPath}${boardRoutes.place}/:gpid?`
export const guestListPlaceDetailsPath = `${guestBoardPath}${boardRoutes.listPlace}/:listPlaceId?`
export const guestHotelDetailsPath = `${guestBoardPath}${boardRoutes.hotel}/:tripAdvisorId?`
export const guestBoardCatchAll = `${guestBoardPath}/*`
export const galleryPath = `${boardIdPath}${boardRoutes.gallery}/:imageId?`
export const boardDetailsPath = `${boardIdPath}${boardRoutes.public.url}/:boardSharedBoardId?`
export const boardPlaceDetailsPath = `${boardIdPath}${boardRoutes.public.url}/:boardSharedBoardId?${boardRoutes.public.listPlace}/:listPlaceId?`
export const placeDetailsPath = `${boardIdPath}${boardRoutes.place}/:gpid?`
export const hotelDetailsPath = `${boardIdPath}${boardRoutes.hotel}/:tripAdvisorId?`
export const listPlaceDetailsPath = `${boardIdPath}${boardRoutes.listPlace}/:listPlaceId?`
export const rootPath = `${boardIdPath}`
export const catchAllPath = `${boardIdPath}/*`

const NewBoard = Loadable(() => import(/* webpackChunkName: "NewBoard" */ '../Board/NewBoard'))
const GuestBoard = Loadable(() => import(/* webpackChunkName: "GuestBoard" */ '../Board/GuestBoard'))
const Board = Loadable(() => import(/* webpackChunkName: "Board" */ '../Board'))

export const routes = () => [
  {
    // This route needs to come before boardIdPath due to route handling rules
    path: newBoardPath,
    exact: true,
    component: NewBoard,
    track: 'New board page'
  },
  {
    path: [
      guestBoardPath,
      guestBoardDetailsPath,
      guestBoardPlaceDetailsPath,
      guestPlaceDetailsPath,
      guestListPlaceDetailsPath,
      guestHotelDetailsPath,
      guestBoardCatchAll
    ],
    exact: true,
    component: GuestBoard,
    track: 'Guest Board'
  },
  {
    // React 5.0 supports multiple routes that resolve to the same component
    // This prevents remounting components if we simply want to update the url.
    // Same as before, the order matters here
    path: [
      galleryPath,
      boardDetailsPath,
      boardPlaceDetailsPath,
      placeDetailsPath,
      listPlaceDetailsPath,
      hotelDetailsPath,
      rootPath,
      catchAllPath
    ],
    exact: true,
    component: Board,
    track: 'Board',
    isPrivate: true
  }
]

export default routes
