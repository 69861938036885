import produce from 'immer'
import {
  FOLLOW_BOARD,
  UNFOLLOW_BOARD,
  DELETE_BOARD_FOLLOW
} from 'client/app/js/services/boards/types'

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  switch (action.type) {
    case FOLLOW_BOARD: {
      const { user, board, type, meta } = action.data
      const { uid } = user
      const { bid } = board
      const { followersOrder, totalFollowers } = draft[bid].follows ?? {}

      if (!followersOrder.includes(uid)) {
        draft[bid].follows.followers[uid] = {
          user,
          type,
          meta
        }
        draft[bid].follows.followersOrder.push(uid)
        draft[bid].follows.totalFollowers = totalFollowers + 1
      }
      break
    }
    case UNFOLLOW_BOARD:
    case DELETE_BOARD_FOLLOW: {
      const { bid, uid } = action.data
      const { followersOrder, totalFollowers } = draft[bid].follows ?? {}
      const followerIndex = followersOrder.indexOf(uid)

      if (followerIndex >= 0) {
        delete draft[bid].follows.followers[uid]
        draft[bid].follows.followersOrder.splice(followerIndex, 1)
        draft[bid].follows.totalFollowers = totalFollowers - 1
      }
      break
    }
  }
}, {})
