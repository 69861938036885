import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createPath } from 'history'
import { Link as ReactRouterLink, NavLink } from 'react-router-dom'
import { trackEvent } from '../../utils/tracking'
import RouteMap, { isEmbedRoute } from 'common/configs/routeMap'
import styles from './Link.module.scss'

class Link extends Component {
  // Converts to-function and to-object into to-string
  normalizeTo (to) {
    let toStr = to ?? this.props.to

    // If to is a function, get its return value
    if (typeof toStr === 'function') {
      toStr = toStr()
    }

    // If to is a history.location object, convert it to path string
    if (typeof toStr === 'object') {
      toStr = createPath(toStr)
    }

    return toStr
  }

  // Embed routes should treat links differently and behave differently
  // This is a temporary solution in lieu of writing a brand new component system for embeds
  isEmbedToNonEmbed () {
    const isToEmbedRoute = this.normalizeTo()?.startsWith(RouteMap.embed.url)

    return (isEmbedRoute() && !isToEmbedRoute)
  }

  onAnchorClick = (evt, url) => {
    if (this.props.silent) {
      evt.preventDefault()
    } else {
      trackEvent('Anchor Link Clicked', {
        'url': url,
        ...this.props.trackProps
      })
    }

    if (this.props.onClick) {
      this.props.onClick(evt)
    }
  }

  onNavClick = (evt) => {
    const { name, event = {} } = this.props.trackProps || {}

    if (name) {
      trackEvent(name, event)
    }

    if (this.props.onClick) {
      this.props.onClick(evt)
    }
  }

  // See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/NavLink.md
  renderRouterNavLink () {
    const linkProps = {
      to: this.props.to,
      replace: this.props.replace,
      className: cx(this.props.className, {
        [styles['text-decoration-none']]: this.props.disableDecoration
      }),
      exact: true,
      onClick: this.onNavClick
    }

    return (
      <NavLink {...linkProps}>
        {this.props.children}
      </NavLink>
    )
  }

  renderRouterLink () {
    const linkProps = {
      to: this.props.to,
      replace: this.props.replace,
      className: cx(this.props.className, {
        [styles['text-decoration-none']]: this.props.disableDecoration
      }),
      onClick: this.onNavClick
    }

    return (
      <ReactRouterLink {...linkProps}>
        {this.props.children}
      </ReactRouterLink>
    )
  }

  renderAnchorLink (options = {}) {
    // Use href if it's different from the main url
    const url = this.props.href || this.normalizeTo(this.props.to)

    const anchorProps = {
      onClick: (evt) => this.onAnchorClick(evt, url),
      href: url,
      className: cx(this.props.className, {
        [styles['text-decoration-none']]: this.props.disableDecoration
      }),
      ...options
    }

    return (
      <a {...anchorProps}>{this.props.children}</a>
    )
  }

  render () {
    let link = null
    const isEmbedToNonEmbed = this.isEmbedToNonEmbed()

    if (this.props.anchor || isEmbedToNonEmbed) {
      let options = {}

      // Open new tab/window if embed tries to open any non-embed routes
      const isNewTab = this.props.isNewTab || isEmbedToNonEmbed

      if (isNewTab) {
        options = {
          target: '_blank',
          rel: 'noopener'
        }
      }

      link = this.renderAnchorLink(options)
    } else if (this.props.navLink) {
      link = this.renderRouterNavLink()
    } else {
      link = this.renderRouterLink()
    }

    return link
  }
}

Link.defaultProps = {
  href: null,
  anchor: false,
  silent: false,
  navLink: false,
  replace: false,
  isNewTab: false,
  disableDecoration: false,
  className: '',
  trackProps: {}
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  href: PropTypes.string,
  className: PropTypes.string,
  anchor: PropTypes.bool,
  silent: PropTypes.bool,
  navLink: PropTypes.bool,
  replace: PropTypes.bool,
  isNewTab: PropTypes.bool,
  disableDecoration: PropTypes.bool,
  trackProps: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default Link
