export const BEGIN_ADD_PLACE = 'BEGIN_ADD_PLACE'
export const COMMIT_ADD_PLACE = 'COMMIT_ADD_PLACE'
export const BEGIN_UPDATE_PLACE = 'BEGIN_UPDATE_PLACE'
export const COMMIT_UPDATE_PLACE = 'COMMIT_UPDATE_PLACE'
export const PREEMPT_UPDATE_PLACE_ERROR = 'PREEMPT_UPDATE_PLACE_ERROR'
export const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS'
export const UPDATE_PLACE_ERROR = 'UPDATE_PLACE_ERROR'
export const COMMIT_DELETE_PLACE = 'COMMIT_DELETE_PLACE'
export const ADD_LIST_PLACE_TAG = 'ADD_LIST_PLACE_TAG'
export const DELETE_LIST_PLACE_TAG = 'DELETE_LIST_PLACE_TAG'
