import editStatesReducer, { selectors as editStatesSelectors } from './editStates'

// ------------------------------------
// Reducer
// ------------------------------------
export default editStatesReducer

// ------------------------------------
// Selectors
// ------------------------------------
export const selectors = {
  ...editStatesSelectors
}
