import produce from 'immer'
import forOwn from 'lodash/forOwn'
import { nestReducers, pipeReducers } from 'client/shared/js/utils/reducer'
import { bindSelectors } from 'client/shared/js/utils/selector'
import { merge } from 'client/shared/js/utils/object'
import currentBoardReducer from './currentBoard'
import listOrderReducer from './listOrder'
import galleryReducer from './gallery'
import collaboratorsReducer from './collaborators'
import followsReducer from './follows'
import searchReducer from './search'
import suggestionsReducer from './suggestions'
import notesReducer from './notes'
import backgroundPhotosReducer from './backgroundPhotos'
import photoLinksReducer from './photoLinks'
import guestReducer from './guest'
import hotelsReducer from './hotels'
import editStatesReducer, { selectors as editStatesSelectors } from '../../../scenes/Board/reducer'
import {
  FETCH_BOARDS_SUCCESS,
  FETCH_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_SUCCESS,
  FETCH_PUBLIC_BOARD_DETAILS_SUCCESS,
  CREATE_BOARD_SUCCESS,
  UPDATE_BOARD,
  UPDATE_BOARD_SUCCESS,
  UPDATE_BOARD_SETTINGS,
  DELETE_BOARD_SUCCESS,
  UNFOLLOW_BOARD,
  ADD_BOARD_TAG,
  DELETE_BOARD_TAG
} from '../types'
import {
  COMMIT_REORDER_PLACES_BETWEEN_LISTS,
  REORDER_PLACES_BETWEEN_LISTS_SUCCESS
} from '../../lists/types'
import {
  FETCH_USER_FROM_USERNAME_SUCCESS
} from '../../../scenes/Username/types'
import {
  FETCH_USER_SUCCESS
} from '../../users/types'
import {
  GUEST_CREATE_BOARD,
  GUEST_UPDATE_BOARD,
  GUEST_REORDER_PLACES_BETWEEN_LISTS,
  GUEST_ADD_BOARD_TAG,
  GUEST_DELETE_BOARD_TAG
} from '../../guest/types'

const initialState = {
  currentBoard: null,
  publicBoards: {}
}

const mapSharedToBoardId = (draft, boards) => {
  forOwn(boards, ({ bid, sharedBoardId }) => {
    if (bid && sharedBoardId) {
      draft.publicBoards[sharedBoardId] = { bid, sharedBoardId }
    }
  })
}

// ------------------------------------
// Reducer
// ------------------------------------
const boardReducer = produce((draft, action) => {
  const { bid } = action.data?.result ?? {}
  const { boards } = action.data?.entities ?? {}

  switch (action.type) {
    case FETCH_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_SUCCESS:
    case FETCH_PUBLIC_BOARD_DETAILS_SUCCESS:
    case FETCH_BOARDS_SUCCESS:
    case CREATE_BOARD_SUCCESS:
    case UPDATE_BOARD:
    case UPDATE_BOARD_SUCCESS:
    case UPDATE_BOARD_SETTINGS:
    case FETCH_USER_FROM_USERNAME_SUCCESS:
    case FETCH_USER_SUCCESS:
    case COMMIT_REORDER_PLACES_BETWEEN_LISTS:
    case REORDER_PLACES_BETWEEN_LISTS_SUCCESS:
    case ADD_BOARD_TAG:
    case DELETE_BOARD_TAG:
    case GUEST_UPDATE_BOARD:
    case GUEST_REORDER_PLACES_BETWEEN_LISTS:
    case GUEST_ADD_BOARD_TAG:
    case GUEST_DELETE_BOARD_TAG:
      merge(draft, boards)
      mapSharedToBoardId(draft, boards)
      break

    case GUEST_CREATE_BOARD:
      // This differs from merge that it only goes one nesting level deep
      Object.assign(draft, boards)
      break

    case DELETE_BOARD_SUCCESS:
    case UNFOLLOW_BOARD:
      const { sharedBoardId } = draft[bid] ?? {}
      delete draft.publicBoards[sharedBoardId]
      delete draft[bid]
      break
  }
}, initialState)

export default nestReducers(pipeReducers(
  currentBoardReducer,
  boardReducer,
  listOrderReducer,
  followsReducer,
  galleryReducer,
  collaboratorsReducer,
  searchReducer,
  suggestionsReducer,
  notesReducer,
  backgroundPhotosReducer,
  photoLinksReducer,
  guestReducer,
  hotelsReducer
), {
  editStates: editStatesReducer
})

// ------------------------------------
// Selectors
// ------------------------------------
const selectCurrentBoard = (state) => {
  const boardId = state.currentBoard
  return state[boardId] ?? {}
}

const selectCurrentBoardId = (state) => {
  return state.currentBoard
}

const selectBoard = (state, boardId) => {
  return state[boardId]
}

const selectPublicBoard = (state, sharedBoardId) => {
  const boardId = state.publicBoards[sharedBoardId]?.bid
  return state[boardId]
}

const selectEditStates = (state) => {
  return state.editStates
}

export const selectors = {
  selectCurrentBoard,
  selectCurrentBoardId,
  selectBoard,
  selectPublicBoard,
  selectEditStates,
  ...bindSelectors(selectEditStates, editStatesSelectors)
}
