import {
  UPDATE_USER,
  UPDATE_USER_PROFILE
} from 'client/shared/js/services/user/types'
import PersonalitiesConfig from 'common/configs/personalities'

const initialState = {}

const ACTION_HANDLERS = {
  [UPDATE_USER]: (state, action) => {
    const { data } = action

    return {
      ...state,
      ...data.profile
    }
  },
  [UPDATE_USER_PROFILE]: (state, action) => {
    const { data } = action

    return {
      ...state,
      ...data
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// ------------------------------------
// Selectors
// ------------------------------------

const selectUserPersonalityType = (state) => {
  return state.personalityType
}

const selectUserPersonalityTypeConfig = (state) => {
  const type = selectUserPersonalityType(state)

  return PersonalitiesConfig[type] ?? {}
}

export const selectors = {
  selectUserPersonalityType,
  selectUserPersonalityTypeConfig
}
