
import {
  GET_USER_TOKEN_SUCCESS,
  INVALID_TOKEN
} from './types'
import {
  OK,
  AUTHENTICATING,
  INVALID_SESSION_TOKEN
} from '../../enums/authStatus'

const ACTION_HANDLERS = {
  [GET_USER_TOKEN_SUCCESS]: (state, action) => {
    const { data } = action

    return {
      ...state,
      status: OK,
      token: data.token
    }
  },
  [INVALID_TOKEN]: (state, action) => {
    return {
      ...state,
      token: null,
      status: INVALID_SESSION_TOKEN
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  status: AUTHENTICATING,
  token: ''
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
