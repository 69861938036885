import produce from 'immer'
import * as TYPES from '../../boards/types'

export default produce((draft, action) => {
  const boards = action.data?.result

  switch (action.type) {
    case TYPES.FETCH_BOARDS_SUCCESS:
      return boards
  }
}, null)
