import assign from 'lodash/assign'
import {
  UPDATE_STATUS
} from '../types'
import { bindSelectors } from 'client/shared/js/utils/selector'
import usersReducer, { selectors as usersSelectors } from './users'
import boardsReducer, { selectors as boardsSelectors } from './boards'
import listsReducer, { selectors as listsSelectors } from './lists'
import listPlacesReducer, { selectors as listPlacesSelectors } from './listPlaces'
import placesReducer, { selectors as placesSelectors } from './places'
import destinationsReducer, { selectors as destinationsSelectors } from './destinations'
import popularReducer from './popular'

// ------------------------------------
// Reducer
// ------------------------------------

const setInitialState = (state, action = {}) => {
  return {
    followRequest: {},
    users: usersReducer(state, action),
    boards: boardsReducer(state, action),
    lists: listsReducer(state, action),
    listPlaces: listPlacesReducer(state, action),
    places: placesReducer(state, action),
    destinations: destinationsReducer(state, action),
    popular: popularReducer(state, action)
  }
}

export default (state, action) => {
  if (typeof state === 'undefined') {
    return setInitialState(state, action)
  }

  switch (action.type) {
    case UPDATE_STATUS:
      const status = action.status
      const updatedStatus = assign({ ...state }, status)

      return {
        ...updatedStatus,
        users: usersReducer(state.users, action),
        boards: boardsReducer(state.boards, action),
        lists: listsReducer(state.lists, action),
        listPlaces: listPlacesReducer(state.listPlaces, action),
        places: placesReducer(state.places, action),
        destinations: destinationsReducer(state.destinations, action),
        popular: popularReducer(state.popular, action)
      }
    default:
      return {
        ...state,
        users: usersReducer(state.users, action),
        boards: boardsReducer(state.boards, action),
        lists: listsReducer(state.lists, action),
        listPlaces: listPlacesReducer(state.listPlaces, action),
        places: placesReducer(state.places, action),
        destinations: destinationsReducer(state.destinations, action),
        popular: popularReducer(state.popular, action)
      }
  }
}

// ------------------------------------
// Selectors
// ------------------------------------

const selectUsersStatus = (state) => {
  return state.users
}

const selectBoardsStatus = (state) => {
  return state.boards
}

const selectListsStatus = (state) => {
  return state.lists
}

const selectListPlacesStatus = (state) => {
  return state.listPlaces
}

const selectPlacesStatus = (state) => {
  return state.places
}

const selectDestinationsStatus = (state) => {
  return state.destinations
}

const selectPopularStatus = (state) => {
  return state.popular
}

export const selectors = {
  selectUsersStatus,
  selectBoardsStatus,
  selectListsStatus,
  selectPlacesStatus,
  selectDestinationsStatus,
  selectPopularStatus,
  ...bindSelectors(selectUsersStatus, usersSelectors),
  ...bindSelectors(selectBoardsStatus, boardsSelectors),
  ...bindSelectors(selectListsStatus, listsSelectors),
  ...bindSelectors(selectListPlacesStatus, listPlacesSelectors),
  ...bindSelectors(selectPlacesStatus, placesSelectors),
  ...bindSelectors(selectDestinationsStatus, destinationsSelectors)
}
