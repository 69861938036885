import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './Logo.module.scss'

const Logo = ({
  size = '32px'
}) => {
  const style = {
    height: size,
    width: size
  }

  return (
    <div className={cx(styles['tripadvisor-logo'])} style={style}>
      <img className={cx(styles['logo-image'])} src='/static/assets/img/logos/tripadvisor/default.svg' />
    </div>
  )
}

Logo.propTypes = {
  size: PropTypes.string
}

export default Logo
