import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BadgeIcon from '../BadgeIcon'
import { add, subtract, multiply } from 'client/shared/js/utils/cssMath'
import usePrevious from '../../hooks/usePrevious'
import styles from './ProfilePhoto.module.scss'

const propTypes = {
  backgroundColor: PropTypes.string,
  badge: PropTypes.oneOf([null, 'crown']),
  badgeOptions: PropTypes.object,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  iconType: PropTypes.string,
  photoUrl: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
}

const defaultProps = {
  badge: null,
  borderColor: 'white',
  borderWidth: '0px',
  iconType: 'circle-user',
  onClick: () => false,
  title: '',
  size: '42px'
}

const ProfilePhoto = ({
  backgroundColor,
  badge,
  badgeOptions,
  borderColor,
  borderWidth,
  children,
  className,
  color,
  iconType,
  photoUrl,
  size,
  title,
  ...props
}) => {
  // The fa-circle-user icon is slightly smaller than font-size, so we give it extra 2px
  const userCircleSize = useMemo(() => add(subtract(size, multiply(borderWidth, 2)), '2px'), [size, borderWidth])

  const wrapperStyle = {
    backgroundColor: backgroundColor || 'white',
    border: `${borderWidth} solid ${borderColor || 'white'}`,
    width: size,
    height: size
  }

  const iconStyle = {
    backgroundColor: 'white',
    color: styles.photoColor,
    fontSize: userCircleSize
  }

  const [showPhoto, setShowPhoto] = useState(Boolean(photoUrl))
  const prevPhotoUrl = usePrevious(photoUrl)
  useEffect(() => {
    if (photoUrl !== prevPhotoUrl) {
      setShowPhoto(true)
    }
  }, [photoUrl, prevPhotoUrl])

  const photo = (showPhoto) ? (
    <img
      className={cx(styles['user-image'])}
      src={photoUrl}
      onError={() => setShowPhoto(false)} />
  ) : (
    <span className={cx(styles['user-icon'])}>
      <FontAwesomeIcon
        icon={['fas', 'circle-user']}
        style={iconStyle} />
    </span>
  )

  return (
    <div
      className={cx(styles['profile-avatar'], 'profile-avatar', className)}
      title={title}
      {...props}>
      <BadgeIcon
        type={badge}
        options={badgeOptions}>
        <div
          className={cx(styles['icon-wrapper'])}
          style={wrapperStyle}>
          {photo}
        </div>
      </BadgeIcon>
      {children}
    </div>
  )
}

ProfilePhoto.propTypes = propTypes
ProfilePhoto.defaultProps = defaultProps

export default ProfilePhoto
