import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import StackedIcons from '../StackedIcons'
import ProfilePhoto from '../ProfilePhoto'
import CountIcon from '../CountIcon'
import { getFullName } from 'client/shared/js/utils/stringFormatter'
import { convertColorToHex } from 'client/shared/js/utils/userColor'
import styles from './StackedProfiles.module.scss'

/**
 * Displays user profile photos like a stack of coins
 * @param {String} props.badgeSize The size of the badge
 * @param {Boolean} props.forceLabel The boolean indicating whether or not to force show the label
 * @param {Boolean} props.isColor Set true for using the user color for the border
 * @param {Boolean} props.isReverse Set true for reversing the z-index order
 * @param {String} props.label The the count icon label
 * @param {Number} props.limit The max number of photos to display before the counter
 * @param {Array} props.order The array of uid's
 * @param {String} props.ownerId The owner id. If provided, a crown badge is displayed next to the user
 * @param {String} props.size The icon size
 * @param {String} props.title The title to show for the entire stack
 * @param {Object} props.users The users object
 */
const StackedProfiles = ({ badgeSize, forceLabel, isColor, isReverse, label, limit, order, ownerId, size, users, title, ...props }) => {
  const [profilePhotos, spacing] = useMemo(() => {
    let profilePhotos = []
    let spacing = 'default'

    for (let i = 0; i < order.length; i++) {
      const userId = order[i]
      const currUser = users[userId]

      if (currUser) {
        const userData = currUser.user || currUser.profile || {}
        const { photoUrl, firstName, lastName } = userData
        const badge = userId === ownerId ? 'crown' : null

        let borderColor = null
        if (isColor) {
          const color = currUser.settings?.color || userData.color || null
          borderColor = convertColorToHex(color) || styles.defaultCollaborationColor
        }

        profilePhotos.push(
          <ProfilePhoto
            badge={badge}
            badgeOptions={{ size: badgeSize }}
            borderWidth='3px'
            borderColor={borderColor}
            className={styles['profile-avatar']}
            key={`stacked-photo-${userId}`}
            photoUrl={photoUrl}
            size={size || styles.profilePhotoSize}
            // Do not set the title of the profile if a custom title is provided
            title={(!title) ? getFullName(firstName, lastName) : null} />
        )
      }

      // As soon as we hit the limit, add a count icon and break out
      // Unless forceLabel is provided, then we show the label as long as order > 0
      if (profilePhotos.length > limit || (forceLabel && order.length > 0)) {
        const totalItems = (profilePhotos.length > limit) ? profilePhotos.length : order.length
        spacing = 'compact'
        profilePhotos.push({
          component: (
            <CountIcon
              className={styles['count-icon']}
              count={totalItems - limit}
              borderColor='white'
              label={label}
              size={size || styles.profilePhotoSize} />
          ),
          width: 'auto'
        })

        break
      }
    }

    return [profilePhotos, spacing]
  }, [title, badgeSize, isColor, label, limit, order, ownerId, size, users, forceLabel])

  return (
    <StackedIcons
      title={title}
      icons={profilePhotos}
      isReverse={isReverse}
      length={order.length}
      size={size || styles.profilePhotoSize}
      spacing={spacing}
      {...props} />
  )
}

StackedProfiles.propTypes = {
  title: PropTypes.string,
  badgeSize: PropTypes.string,
  isColor: PropTypes.bool,
  isReverse: PropTypes.bool,
  label: PropTypes.string,
  limit: PropTypes.number,
  order: PropTypes.array,
  ownerId: PropTypes.string,
  size: PropTypes.string,
  users: PropTypes.object,
  forceLabel: PropTypes.bool
}

StackedProfiles.defaultProps = {
  badgeSize: 'medium',
  isColor: false,
  isReverse: false,
  limit: 5,
  order: [],
  ownerId: null,
  users: {},
  forceLabel: false
}

export default StackedProfiles
