import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import BeatLoader from 'react-spinners/BeatLoader'
import BounceLoader from 'react-spinners/BounceLoader'
import styles from './LoadingScreen.module.scss'

const LoadingScreen = ({ children, className, loader, color, size }) => {
  const loadingAnimation = useMemo(() => {
    const loaderProps = {
      ...(size ? { size } : {}),
      color
    }

    switch (loader) {
      case 'beat':
        return <BeatLoader {...loaderProps} />
      case 'bounce':
      default:
        return <BounceLoader {...loaderProps} />
    }
  }, [loader, color, size])

  return useMemo(() => (
    <div className={cx(styles['loading-screen'], className)}>
      {children || loadingAnimation}
    </div>
  ), [className, loadingAnimation, children])
}

LoadingScreen.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loader: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number
}

LoadingScreen.defaultProps = {
  loader: 'bounce',
  color: '#FFD300'
}

export default LoadingScreen
