import React from 'react'
import PropTypes from 'prop-types'
import { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ValueContainer = ({ children, ...props }) => (
  <>
    <div className='searchbar__prefix'>
      <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
    </div>
    <components.ValueContainer {...props}>
      {children}
    </components.ValueContainer>
  </>
)

ValueContainer.propTypes = {
  children: PropTypes.node
}

export default ValueContainer
