import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Popover, PopoverBody } from 'reactstrap'
import './MapMarker.scss'

export default class MapMarker extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    height: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    textColor: PropTypes.string,
    text: PropTypes.node,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    toggle: PropTypes.func,
    uncontrolled: PropTypes.bool,
    pulsate: PropTypes.bool,
    active: PropTypes.bool,
    children: PropTypes.node,

    // Boundaries for popover, can be scrollParent, window, viewport, or any DOM element
    boundariesElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

    // Container element in which to render popover
    popoverContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node, PropTypes.object]),

    // Provided by GoogleMap library
    $hover: PropTypes.bool
  }

  static defaultProps = {
    height: '30px',
    width: '30px',
    color: '#FFA404',
    borderColor: '#FFFFFF',
    textColor: 'white',
    text: '',
    uncontrolled: false,
    pulsate: false,
    active: false,
    isOpen: false
  }

  state = {
    isPopoverOpen: false
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }

    if (this.props.uncontrolled) {
      this.togglePopover()
    }
  }

  togglePopover = () => {
    if (this.props.children) {
      this.setState({
        isPopoverOpen: !this.state.isPopoverOpen
      })
    }
  }

  renderPopover = () => {
    if (this.props.children) {
      let isOpen = this.state.isPopoverOpen
      let toggle = this.togglePopover

      if (!this.props.uncontrolled) {
        isOpen = this.props.isOpen
        toggle = this.props.toggle
      }

      return (
        <Popover
          className='map-marker-popover'
          placement='top'
          trigger='legacy'
          isOpen={isOpen}
          target={this.props.id}
          toggle={toggle}
          boundariesElement={this.props.boundariesElement}
          container={this.props.popoverContainer}>
          <PopoverBody className='p-0'>
            {this.props.children}
          </PopoverBody>
        </Popover>
      )
    }

    return null
  }

  render () {
    const markerStyle = {
      height: this.props.height,
      width: this.props.width,
      border: `4px solid ${this.props.borderColor}`,
      backgroundColor: this.props.color,
      color: this.props.textColor
    }

    const markerClass = classNames('map-marker', {
      'hover': this.props.$hover,
      'active': (this.props.uncontrolled) ? this.state.isPopoverOpen : this.props.active,
      'pulsate': this.props.pulsate
    })

    return (
      <div id={this.props.id} className={markerClass} style={markerStyle} onClick={this.handleClick}>
        <div className='inner-text'>{this.props.text}</div>
        {this.renderPopover()}
      </div>
    )
  }
}
