import produce from 'immer'
import merge from 'lodash/merge'
import { COMMIT_UPDATE_LIST_ORDER } from '../types'
import {
  CREATE_LIST,
  COMMIT_DELETE_LIST
} from '../../lists/types'
import {
  GUEST_CREATE_LIST,
  GUEST_UPDATE_LIST_ORDER,
  GUEST_DELETE_LIST
} from '../../guest/types'

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const bid = draft.currentBoard
  const { lid } = action.data?.result ?? {}
  const { boards } = action.data?.entities ?? {}

  switch (action.type) {
    case CREATE_LIST:
    case COMMIT_UPDATE_LIST_ORDER:
    case GUEST_CREATE_LIST:
    case GUEST_UPDATE_LIST_ORDER:
      merge(draft, boards)
      break

    case COMMIT_DELETE_LIST:
    case GUEST_DELETE_LIST:
      const listIndex = draft[bid].listOrder.indexOf(lid)
      if (listIndex > -1) draft[bid].listOrder.splice(listIndex, 1)
      break
  }
}, {})
