import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  Container,
  Navbar as GlobalNav,
  Nav,
  NavItem,
  Button,
  NavbarToggler,
  Collapse
} from 'reactstrap'
import isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RouteMap from 'common/configs/routeMap'
import BreadCrumbs from './components/BreadCrumbs'
import NavLinksContext from './context'
import NavUserDropdown from '../NavUserDropdown'
import Link from '../Link'
import Search, { searchUtils } from '../Search'
// import { searchAll } from '../../services/search/api'
import { logout } from '../../services/auth/api'
import { shouldShowFeature } from 'client/app/js/components/FeatureFlag'
import { FeatureNames, FlagNames } from 'common/enums/FeatureFlag'
import { DESTINATIONS } from 'client/shared/js/components/Search/enums/indexNames'
import styles from './Navbar.module.scss'
import { isBoolean } from 'lodash'

const FEATURE_NAME = FeatureNames.destinationPage
const FLAG_NAME = FlagNames[FEATURE_NAME].searchDestination

class Navbar extends Component {
  static contextType = NavLinksContext

  searchRef = React.createRef()

  state = {
    isSearchVisibleOnMobile: false,
    isScrolledDown: false,
    isCollapsed: true,
    searchDestination: false
  }

  componentDidMount = () => {
    this.handleNavScroll()
    window.addEventListener('scroll', this.handleNavScroll, { passive: true })
    this.updateSearchDestination()

    const { maps } = this.props

    if (maps) {
      this.sessionToken = new maps.places.AutocompleteSessionToken()
      this.autocomplete = new maps.places.AutocompleteService()
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleNavScroll)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.searchRef.current) {
      // Focus the searchbar automatically when toggling from invisible to visible state
      if (!prevState.isSearchVisibleOnMobile && this.state.isSearchVisibleOnMobile) {
        this.searchRef.current.focus()
      }

      // If we performed a successful navigation and there is no current search query,
      //  reset the search input and state
      if (!searchUtils.getSearchQueryFromUrl()) {
        // eslint-disable-next-line no-unused-expressions
        this.searchRef.current?.clear()
      }
    }

    const isUserFeatureFlagUpdated = prevProps.user !== this.props.user
    const isGlobalFeatureFlagUpdated = prevProps.featureFlags !== this.props.featureFlags
    const isSearchDestinationOverridden = prevProps.searchDestination !== this.props.searchDestination
    const shouldUpdateSearchDestination = isUserFeatureFlagUpdated || isGlobalFeatureFlagUpdated || isSearchDestinationOverridden

    if (shouldUpdateSearchDestination) {
      this.updateSearchDestination()
    }
  }

  updateSearchDestination = () => {
    const { user, featureFlags, searchDestination } = this.props ?? {}

    if (isBoolean(searchDestination)) {
      this.setState({ searchDestination })

      return
    }

    if (!user || !featureFlags) {
      this.setState({ searchDestination : false })

      return
    }

    const featureFlagConfig = {
      featureFlags: featureFlags,
      userFeatureFlags: user.featureFlags,
      featureName: FEATURE_NAME,
      flagName : FLAG_NAME
    }

    this.setState({ searchDestination : shouldShowFeature(featureFlagConfig) })
  }

  getPlaceholderText = () => {
    // let placeholderText = 'Search for users'
    let placeholderText = 'Search'

    if (this.state.searchDestination) {
      // placeholderText = placeholderText.concat(' ', 'or destinations')
      placeholderText = placeholderText.concat(' ', 'for destinations')
    }

    return placeholderText
  }

  toggleNavbar = (isCollapsed) => {
    this.setState({ isCollapsed })
  }

  handleNavScroll = () => {
    if (window.scrollY === 0 && this.state.isScrolledDown) {
      this.setState({
        isScrolledDown: false
      })
      this.props.onScrollCheckpoint(false)
    } else if (window.scrollY > 0 && !this.state.isScrolledDown) {
      this.setState({
        isScrolledDown: true
      })
      this.props.onScrollCheckpoint(true)
    }
  }

  fetchPredictions = (input) => {
    let predictionOptions = {
      input,
      sessionToken: this.sessionToken
    }

    predictionOptions.types = ['(cities)']

    if (this.autocomplete && input) {
      return this.autocomplete.getPlacePredictions(predictionOptions, this.autoCompleteCallback)
    }

    return []
  }

  onSearch = (inputVal) => {
    // FIXME: As of 03/31/2024, we had to disable elastic search until we properly migrate to opensearch
    // const uid = this.props.user.uid

    // const searchPromises = Promise.all([
    //   searchAll(inputVal, uid),
    //   this.state.searchDestination ? this.fetchPredictions(inputVal) : Promise.resolve({})
    // ])

    // return searchPromises.then(([searchAllRes, searchDestinationRes]) => {
    //   const { users = [] } = searchAllRes.data
    //   const { predictions = [] } = searchDestinationRes

    //   const indexedDestinations = predictions.map((destination) => ({
    //     _index: DESTINATIONS,
    //     _source: destination
    //   }))

    //   return [...users, ...indexedDestinations]
    // })

    if (!this.state.searchDestination) {
      return []
    }

    return this.fetchPredictions(inputVal).then((res) => {
      const { predictions = [] } = res

      const indexedDestinations = predictions.map((destination) => ({
        _index: DESTINATIONS,
        _source: destination
      }))

      return indexedDestinations
    })
  }

  onSearchSelectOption = (selectedOption) => {
    const { url, label } = selectedOption
    const updatedUrl = `${url}?q=${label}`

    this.props.navigate(updatedUrl)
  }

  // Manages whether the searchbar has been toggled to be viewable on mobile
  toggleSearchBar = () => {
    this.setState({
      isSearchVisibleOnMobile: !this.state.isSearchVisibleOnMobile
    })
  }

  renderBrandLink = () => {
    const linkProps = {
      to: this.props.url,
      anchor: !this.props.authenticated
    }

    const logoClassName = cx({
      'desktop-logo': !!this.props.mobileLogoSrc
    })

    const mobileLogo = this.props.mobileLogoSrc && (
      <img className='mobile-logo' src={this.props.mobileLogoSrc} />
    )

    return (
      <div className='navbar-brand'>
        {this.props.url ? (
          <>
            <Link className={logoClassName} {...linkProps}>
              <img src={this.props.logoSrc} />
            </Link>
            {mobileLogo && (
              <Link className='mobile-logo' {...linkProps}>
                {mobileLogo}
              </Link>
            )}
          </>
        ) : (
          <>
            <img className={logoClassName} src={this.props.logoSrc} />
            {mobileLogo}
          </>
        )}
      </div>
    )
  }

  renderNavItems = () => {
    const Items = this.props.navItems.map(({ title, link, anchor, authOnly, desktopOnly, mobileOnly }) => (
      (!authOnly || this.props.authenticated) ? (
        <NavItem
          key={`navItemLink-${title}`}
          className={cx(styles['nav-item-link'], {
            'desktop-only': !!desktopOnly,
            'mobile-only': !!mobileOnly
          })}>
          <Link
            onClick={anchor ? null : () => this.toggleNavbar(true)}
            to={link}
            anchor={anchor}
            navLink>
            {title}
          </Link>
        </NavItem>
      ) : null
    ))

    return (Items.length > 0) ? (
      <Nav className={cx(styles['nav-item-links'])}>
        {Items}
      </Nav>
    ) : null
  }

  renderSearchBar = () => {
    const navSearchClass = cx(styles['nav-searchbar'], 'nav-searchbar', {
      'd-flex': this.state.isSearchVisibleOnMobile
    })
    const openBtnClass = cx({ 'd-none': this.state.isSearchVisibleOnMobile })
    const closeBtnClass = cx(styles['close-search-btn'], 'dark ml-3')

    const OpenBtn = this.props.searchBtn
      ? this.props.searchBtn({ classname: openBtnClass, onClick: this.toggleSearchBar })
      : (
        <Button
          color='info'
          className={cx(styles['open-search-btn'], 'open-search-btn round flat muted', openBtnClass)} onClick={this.toggleSearchBar}>
          <FontAwesomeIcon icon={['fas', 'magnifying-glass']} className='font-inherit' />
        </Button>

      )

    return (this.props.searchable) ? (
      <>
        <div className={navSearchClass}>
          <Search
            ref={this.searchRef}
            user={this.props.user}
            maps={this.props.maps}
            featureFlags={this.props.featureFlags}
            search={this.onSearch}
            onSelectOption={this.onSearchSelectOption}
            navigate={this.props.navigate}
            placeholder={this.getPlaceholderText()}
            defaultInputValue={searchUtils.getSearchQueryFromUrl()} />
          <Button color='link' className={closeBtnClass} onClick={this.toggleSearchBar}>
            <FontAwesomeIcon icon={['fal', 'times-circle']} />
          </Button>
        </div>
        <div className={cx(styles['search-btn-wrapper'])}>
          {OpenBtn}
        </div>
      </>
    ) : null
  }

  renderAccountLinks = () => {
    const { btnOptions } = this.props

    return btnOptions.map(({ className, type, to = '', track = {}, text = '', ...btnOptionProps }) => {
      let btnClass = cx('flat', className)

      if (type === 'signup') {
        text = text || 'Sign up'
        to = to || `${RouteMap.account.url}${RouteMap.account.signup}`
        track = !isEmpty(track) ? track : { 'Label': 'Sign up button' }
        btnClass = cx(styles['account-btn'], 'sign-up-btn', btnClass)
      } else if (type === 'login') {
        text = text || 'Login'
        to = to || `${RouteMap.account.url}${RouteMap.account.login}`
        track = !isEmpty(track) ? track : { 'Label': 'Login button' }
        btnClass = cx(styles['account-btn'], 'login-btn', btnClass)
      }

      return (
        <NavItem key={`nav-btn-${type || text}`} className={cx(styles['nav-item'])}>
          <Link to={to} trackProps={track} anchor>
            <Button className={btnClass} {...btnOptionProps}>
              {text}
            </Button>
          </Link>
        </NavItem>
      )
    })
  }

  renderUserDropdown = () => {
    if (this.props.user?.uid) {
      return (
        <NavUserDropdown
          user={this.props.user}
          onLogout={this.props.onLogout}
          navigate={this.props.navigate} />
      )
    } else if (!this.props.authenticated) {
      return this.renderAccountLinks()
    }

    return null
  }

  renderCollapsibleNav = () => {
    const navItems = this.renderNavItems()
    const isCollapsibleNav = this.props.collapsible
    const revealTriggerClass = cx({
      [styles['reveal-tablet']]: this.props.collapibleTrigger === 'tablet',
      [styles['reveal-mobile']]: this.props.collapibleTrigger === 'mobile'
    })

    return (
      <div className='nav-links-container d-flex flex-row'>
        {isCollapsibleNav && (
          <NavbarToggler
            className={cx(styles['navbar-toggler'], 'mr-3', revealTriggerClass)}
            onClick={() => this.toggleNavbar(!this.state.isCollapsed)} />
        )}
        {this.renderBrandLink()}
        {isCollapsibleNav ? (
          <Collapse
            className={cx(styles['navbar-collapse'])}
            isOpen={!this.state.isCollapsed}
            navbar>
            {navItems}
            {!this.props.authenticated && (
              <ul className={cx(styles['collapsible-account-links'], revealTriggerClass)}>
                {this.renderAccountLinks()}
              </ul>
            )}
          </Collapse>
        ) : navItems}
      </div>
    )
  }

  render () {
    const shouldRenderBreadCrumbs = (this.context.navLinks.length > 0 && this.props.authenticated)
    const navPadderClass = cx({
      [styles['navbar-padder']]: this.props.fixed && !shouldRenderBreadCrumbs,
      [styles['navbar-padder-with-breadcrumbs']]: shouldRenderBreadCrumbs && this.props.fixed
    })
    const navClass = cx(styles['global-nav'], 'global-nav', this.props.className, {
      [styles['nav-shadow']]: this.props.fixed && this.state.isScrolledDown,
      [styles['nav-public']]: !this.props.authenticated
    })
    const navProps = {
      id: 'hipherd-navbar',
      color: this.props.color,
      className: navClass,
      light: true,
      ...((this.props.fixed) ? { fixed: this.props.fixed } : {})
    }

    const NavTag = (this.props.container) ? Container : 'div'

    return (
      <>
        <GlobalNav {...navProps}>
          <NavTag
            className={cx(styles['navbar-primary'])}
            {...(this.props.fluid && this.props.container ? { fluid: true } : {})}>
            {this.renderCollapsibleNav()}
            {this.renderSearchBar()}
            <Nav className='flex-row align-items-center' navbar>
              {this.props.children}
              {this.renderUserDropdown()}
            </Nav>
          </NavTag>
          {shouldRenderBreadCrumbs ? (<BreadCrumbs navLinks={this.context.navLinks} />) : null}
        </GlobalNav>
        {(!this.props.disableFixedPadding && this.props.fixed) ? (
          <div className={navPadderClass} />
        ) : null}
      </>
    )
  }
}

Navbar.defaultProps = {
  url: '/',
  user: {},
  color: 'white',
  logoSrc: '/static/assets/img/logos/brand/default.svg',
  navItems: [],
  onLogout: logout,
  onScrollCheckpoint: () => false,
  fixed: 'top',
  searchable: false,
  authenticated: false,
  disableFixedPadding: false,
  collapsible: false,
  collapibleTrigger: 'tablet',
  container: false,
  fluid: false,
  btnOptions: [{
    type: 'login',
    color: 'light'
  }, {
    type: 'signup',
    color: 'primary'
  }]
}

Navbar.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  user: PropTypes.object,
  maps: PropTypes.object,
  featureFlags: PropTypes.object,
  color: PropTypes.string,
  logoSrc: PropTypes.string,
  mobileLogoSrc: PropTypes.string,
  navItems: PropTypes.array,
  onLogout: PropTypes.func,
  onScrollCheckpoint: PropTypes.func,
  navigate: PropTypes.func,
  searchable: PropTypes.bool,
  searchBtn: PropTypes.func,
  authenticated: PropTypes.bool,
  fixed: PropTypes.oneOf(['top', 'bottom', 'left', 'right', false]),
  disableFixedPadding: PropTypes.bool,
  collapsible: PropTypes.bool,
  collapibleTrigger: PropTypes.oneOf(['mobile', 'tablet']),
  container: PropTypes.bool,
  fluid: PropTypes.bool,
  btnOptions: PropTypes.array,
  children: PropTypes.node,
  // Allow overriding searchDestination feature flag. Useful for pages that
  // don't want to create a redux store
  searchDestination: PropTypes.bool
}

export default Navbar
