import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'client/shared/js/components/Link'
import styles from './UsernameTag.module.scss'

const UsernameTag = ({ username, bold, link, className }) => {
  const usernameLabelClass = cx(styles['username-label'], { bold })

  if (!username) {
    return null
  }

  const Tag = (
    <span className={cx(className, 'username-tag', 'align-bottom')}>
      <FontAwesomeIcon icon={['fal', 'at']} className={cx(styles['username-at-icon'], 'font-inherit')} />
      <span className={usernameLabelClass}>{username}</span>
    </span>
  )

  return (!link) ? Tag : <Link to={`/${username}`}>{Tag}</Link>
}

UsernameTag.defaultProps = {
  bold: false,
  link: false
}

UsernameTag.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string,
  bold: PropTypes.bool,
  link: PropTypes.bool
}

export default UsernameTag
