import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserLogger } from 'client/shared/js/utils/browser'
import { auth } from 'client/shared/js/utils/firebase'
import RouteMap from 'common/configs/routeMap'
import { ChatWidget } from 'client/shared/js/utils/chatSupport'
import { trackPage } from 'client/shared/js/utils/tracking'
import 'client/shared/js/components/RegisterIcons'

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('hipherd-landing-root')

let render = () => {
  trackPage({
    'PageName': 'Landing Page'
  })

  ReactDOM.render(
    <App />,
    MOUNT_NODE
  )
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        console.error(error)
        renderError(error)
      }
    }

    module.hot.accept('./App', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      })
    )
  }
}

// ========================================================
// Set up client side logger
// ========================================================
BrowserLogger()

// ========================================================
// Set up landing page + auth listener
// ========================================================
auth.onAuthStateChanged((user) => {
  if (user) {
    window.location = RouteMap.home.url
  } else {
    render()

    if (__HELPSCOUT_HELP_ID__) {
      ChatWidget('init', __HELPSCOUT_HELP_ID__)
    }
  }
})
