export const OK = 'OK'
export const AUTHENTICATING = 'AUTHENTICATING'
export const INVALID_SESSION_TOKEN = 'INVALID_SESSION_TOKEN'

// Firebase auth errors
// See: https://firebase.google.com/docs/reference/js/firebase.User
export const USER_MISMATCH = 'auth/user-mismatch'
export const USER_NOT_FOUND = 'auth/user-not-found'
export const INVALID_CREDENTIAL = 'auth/invalid-credential'
export const INVALID_EMAIL = 'auth/invalid-email'
export const WRONG_PASSWORD = 'auth/wrong-password'
export const EMAIL_ALREADY_EXISTS = 'auth/email-already-exists'
export const EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use'
export const NOT_ALLOWED = 'auth/operation-not-allowed'
export const WEAK_PASSWORD = 'auth/weak-password'
export const ALREADY_EXISTS_CREDENTIALS = 'auth/account-exists-with-different-credential'
