export const GUEST_CREATE_BOARD = 'GUEST_CREATE_BOARD'
export const GUEST_EDITING_BOARD = 'GUEST_EDITING_BOARD'
export const GUEST_UPDATE_BOARD = 'GUEST_UPDATE_BOARD'
export const GUEST_UPDATE_BOARD_NOTES = 'GUEST_UPDATE_BOARD_NOTES'
export const GUEST_UPDATE_LIST_ORDER = 'GUEST_UPDATE_LIST_ORDER'
export const GUEST_ADD_BOARD_TAG = 'GUEST_ADD_BOARD_TAG'
export const GUEST_DELETE_BOARD_TAG = 'GUEST_DELETE_BOARD_TAG'

export const GUEST_CREATE_LIST = 'GUEST_CREATE_LIST'
export const GUEST_UPDATE_LIST = 'GUEST_UPDATE_LIST'
export const GUEST_DELETE_LIST = 'GUEST_DELETE_LIST'
export const GUEST_REORDER_PLACES_WITHIN_LIST = 'GUEST_REORDER_PLACES_WITHIN_LIST'
export const GUEST_REORDER_PLACES_BETWEEN_LISTS = 'GUEST_REORDER_PLACES_BETWEEN_LISTS'

export const GUEST_ADD_PLACE = 'GUEST_ADD_PLACE'
export const GUEST_UPDATE_PLACE = 'GUEST_UPDATE_PLACE'
export const GUEST_DELETE_PLACE = 'GUEST_DELETE_PLACE'
export const GUEST_ADD_LIST_PLACE_TAG = 'GUEST_ADD_LIST_PLACE_TAG'
export const GUEST_DELETE_LIST_PLACE_TAG = 'GUEST_DELETE_LIST_PLACE_TAG'
