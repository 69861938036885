import produce from 'immer'
import {
  FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS
} from 'client/app/js/services/boards/types'

export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}
  const { boards } = action.data?.entities ?? []

  switch (action.type) {
    case FETCH_SEARCH_SUGGESTIONS_FOR_BOARD_SUCCESS:
      const currSearch = draft[bid].search || {}

      draft[bid].search = {
        ...currSearch,
        suggestions: boards[bid].search?.suggestions || {}
      }
      break
  }
})
