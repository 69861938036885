import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Alert } from 'reactstrap'

const Banner = ({
  className = '',
  alertClass = '',
  color = 'primary',
  onDismiss,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
    onDismiss()
  }, [onDismiss])

  const alertProps = useMemo(() => ({
    color,
    isOpen,
    className: cx(alertClass, 'p-0'),
    ...(onDismiss ? { toggle: handleDismiss } : {}),
    ...props
  }), [alertClass, color, isOpen, onDismiss, handleDismiss, props])

  return (
    <Alert {...alertProps}>
      <div className={cx(className, 'banner')}>
        {children}
      </div>
    </Alert>
  )
}

Banner.propTypes = {
  className: PropTypes.string,
  alertClass: PropTypes.string,
  color: PropTypes.string,
  onDismiss: PropTypes.func,
  children: PropTypes.node
}

export default Banner
