import produce from 'immer'
import { REHYDRATE } from 'redux-persist'
import pickBy from 'lodash/pickBy'
import { merge } from 'client/shared/js/utils/object'
import * as listPlacesTYPES from './types'
import * as boardsTYPES from '../boards/types'
import * as listsTYPES from '../lists/types'
import * as guestTYPES from '../guest/types'

const TYPES = {
  ...listPlacesTYPES,
  ...boardsTYPES,
  ...listsTYPES,
  ...guestTYPES
}

const initialState = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { lpid } = action.data?.result ?? {}
  const { listPlaces } = action.data?.entities ?? {}

  switch (action.type) {
    case TYPES.FETCH_BOARD_SUCCESS:
    case TYPES.FETCH_PUBLIC_BOARD_SUCCESS:
    case TYPES.FETCH_PUBLIC_BOARD_DETAILS_SUCCESS:
      Object.assign(draft, listPlaces)
      break

    case TYPES.COMMIT_ADD_PLACE:
    case TYPES.COMMIT_UPDATE_PLACE:
    case TYPES.COLLABORATOR_UPDATE_PLACE:
    case TYPES.UPDATE_PLACE_SUCCESS:
    case TYPES.COMMIT_REORDER_PLACES_BETWEEN_LISTS:
    case TYPES.REORDER_PLACES_BETWEEN_LISTS_SUCCESS:
    case TYPES.ADD_LIST_PLACE_TAG:
    case TYPES.DELETE_LIST_PLACE_TAG:
    case TYPES.GUEST_ADD_PLACE:
    case TYPES.GUEST_UPDATE_PLACE:
    case TYPES.GUEST_REORDER_PLACES_BETWEEN_LISTS:
    case TYPES.GUEST_ADD_LIST_PLACE_TAG:
    case TYPES.GUEST_DELETE_LIST_PLACE_TAG:
      merge(draft, listPlaces)
      break

    case TYPES.COMMIT_DELETE_PLACE:
    case TYPES.GUEST_DELETE_PLACE:
      delete draft[lpid]
      break

    case REHYDRATE:
      if (action.payload?.guest?.rid === 'guest-reducer' && action.payload.guest.listPlaces) {
        merge(draft, action.payload.guest.listPlaces)
      }
      break
  }
}, initialState)

// ------------------------------------
// Selectors
// ------------------------------------
const selectListPlace = (state, listPlaceId) => {
  return state[listPlaceId] ?? {}
}

const selectListPlacesWithGpid = (state) => {
  return pickBy(state, ({ gpid }) => gpid)
}

export const selectors = {
  selectListPlace,
  selectListPlacesWithGpid
}
