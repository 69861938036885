import produce from 'immer'
import { merge } from 'client/shared/js/utils/object'
import * as TYPES from '../types'

const initialState = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default produce((draft, action) => {
  const { status } = action.data ?? {}

  switch (action.type) {
    case TYPES.UPDATE_POPULAR_ITEMS_STATUS:
      merge(draft, status)
      break
  }
}, initialState)
