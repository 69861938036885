import {
  SIGHT_SEEKER,
  ALL_AROUND,
  CHILLEST,
  URBAN,
  TRADITIONALIST,
  LOCAL,
  TRAIL_TREKKER
} from '../enums/PersonalityTypes'

export default {
  [URBAN]: {
    name: 'Urban Explorer',
    color: '#F16B49',
    description: 'Constantly moving, you\'re not living life unless you are part of the hustle and bustle. You love to get lost in the city and enjoy a variety of things to do, whether that involves shopping, eating a ton, attending a music show, or enjoying the bar scene.',
    imgSrc: '/static/assets/img/personalities/icons/urban.png',
    share: 'Constantly moving, I am not living life unless I am part of the hustle and bustle. I am an Urban Explorer.'
  },
  [CHILLEST]: {
    name: 'Chillest Lounger',
    color: '#736AB0',
    description: 'Sometimes you just want to be pampered, and there\'s nothing wrong with that. You love to do things where you get to relax, mellow out, and disconnect from the world. You don\'t need to do much to enjoy yourself - because you deserve a break.',
    imgSrc: '/static/assets/img/personalities/icons/chillest.png',
    share: 'All my travels involve relaxing and disconnecting myself from the world. I am the Chillest Lounger.'
  },
  [SIGHT_SEEKER]: {
    name: 'Sight Seeker',
    color: '#02ADD8',
    description: 'Unique experiences are your jam. You frequently attend the most popular events while actively seeking top attractions.  You make sure to maximize your trips and are most commonly associated as a "bucket-lister" traveler.',
    imgSrc: '/static/assets/img/personalities/icons/sight-seeker.png',
    share: 'I love to cross things off my bucket list and have unique experiences. I am a Sight Seeker. Find our your travel personality!'
  },
  [TRAIL_TREKKER]: {
    name: 'Trail Trekker',
    color: '#CACCCD',
    dark: true,
    description: 'Nature gives you strength and energy. A majority of your excursions are about appreciating the world around you. Road trips, hiking, outdoor activities, or anything adrenaline intensive gives you sustenance.',
    imgSrc: '/static/assets/img/personalities/icons/trail-trekker.png',
    share: 'The outdoors give me strength and energy. I am a Trail Trekker.'
  },
  [LOCAL]: {
    name: 'Local Wanderer',
    color: '#A7A5A4',
    description: 'Going to the top destinations isn\'t a priority as long as you\'re living like a local. You enjoy going off the beaten path, participating in anything that makes you feel a part of the local culture. You are the most flexible traveler because everything is an experience.',
    imgSrc: '/static/assets/img/personalities/icons/local.png',
    share: 'There\'s nothing more satisfying than being a part of the locals. I am a Local Wanderer.'
  },
  [ALL_AROUND]: {
    name: 'All-Around Adventurer',
    color: '#F8CF58',
    dark: true,
    description: 'Considered the most dynamic traveler, you like to travel to places where there is a lot to see or do. You are a moderate planner and try to cross the top things to do off your list, but overall you go with the flow.',
    imgSrc: '/static/assets/img/personalities/icons/all-around.png',
    share: 'Traveling is one of the most rewarding aspects of my life. I am an All-Around Adventurer.'
  },
  [TRADITIONALIST]: {
    name: 'Traditional Traveler',
    color: '#D8A577',
    description: 'Don\'t fix what ain\'t broke. You usually like to carefully plan everything because you\'re often traveling in groups. Regardless of whether you are with family or friends, the end destination isn\'t as important as the collective journey. You will go to touristy destinations, sometimes purchase a prepackaged deal, and play it safe.',
    imgSrc: '/static/assets/img/personalities/icons/traditional.png',
    share: 'A successful adventure involves a bit of planning & bringing along great friends/family. I am a Traditional Traveler.'
  }
}
