import {
  FETCH_ANNOUNCEMENTS_SUCCESS
} from './types'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      const data = action.data

      return data
    default:
      return [...state]
  }
}
