import axios from 'axios'
import apiConfig from 'common/configs/api'
import { getHeaders } from '../auth/api'

const { apiUrl, featureFlags } = apiConfig
const featureFlagsUrl = `${apiUrl}${featureFlags.url}`

export const getFeatureFlags = () => {
  const headers = getHeaders()

  return axios.get(featureFlagsUrl, { headers })
}

export default {
  getFeatureFlags
}
