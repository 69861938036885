/**
 * Reusable currentBoard reducer
 */
import produce from 'immer'
import * as TYPES from '../types'
import { GUEST_EDITING_BOARD } from 'client/app/js/services/guest/types'

export default produce((draft, action) => {
  const { bid } = action.data?.result ?? {}

  switch (action.type) {
    case GUEST_EDITING_BOARD:
    case TYPES.FETCH_BOARD_SUCCESS:
    case TYPES.FETCH_PUBLIC_BOARD_SUCCESS:
      draft.currentBoard = bid
  }
})
