import { UPDATE_USER } from 'client/shared/js/services/user/types'
import { UPDATE_USER_FEATURE_FLAGS } from 'client/shared/js/services/featureFlags/types'

const initialState = {}

const ACTION_HANDLERS = {
  [UPDATE_USER]: (state, action) => {
    const { featureFlags } = action.data

    return {
      ...state,
      ...featureFlags
    }
  },
  [UPDATE_USER_FEATURE_FLAGS]: (state, action) => {
    const { featureName, featureFlags } = action.data

    return {
      ...state,
      [featureName]: {
        ...state[featureName],
        ...featureFlags
      }
    }
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
