import produce from 'immer'
import merge from 'lodash/merge'
import forOwn from 'lodash/forOwn'
import * as TYPES from './types'

const initialState = {
  usernames: {}
}

const mapUsernameToUserId = (draft, users) => {
  forOwn(users, ({ uid, usernameId }) => {
    if (uid && usernameId) {
      draft.usernames[usernameId] = { uid, usernameId }
    }
  })
}

// ------------------------------------
// Reducer
// ------------------------------------

export default produce((draft, action) => {
  const { users } = action.data?.entities ?? {}

  switch (action.type) {
    case TYPES.FETCH_USER_SUCCESS:
      mapUsernameToUserId(draft, users)
      merge(draft, users)
      break
  }
}, initialState)

// ------------------------------------
// Selectors
// ------------------------------------

export const selectUser = (state, userId) => {
  return state[userId] ?? null
}

export const selectUserByUsername = (state, username) => {
  if (!username) return null

  const usernameId = username.toLowerCase()
  const { uid } = state.usernames?.[usernameId] ?? {}

  if (!uid) return null

  return state[uid] ?? null
}

export const selectors = {
  selectUser,
  selectUserByUsername
}
