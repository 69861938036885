import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './CountIcon.module.scss'

/**
 * Renders count icon
 * Note: all undocumented props get passed into the div container
 * @param {String} props.borderWidth The border width
 * @param {Number} props.count The displayed number
 * @param {String} props.label The label next to the count
 * @param {String} props.size The icon size
 */
const CountIcon = ({ backgroundColor, borderColor, borderWidth, className, count, label, size, ...props }) => {
  backgroundColor = useMemo(() => styles[backgroundColor] || backgroundColor, [backgroundColor])

  const iconStyle = useMemo(() => ({
    border: `${borderWidth} solid ${borderColor}`,
    backgroundColor,
    minWidth: size,
    minHeight: size
  }), [backgroundColor, borderColor, borderWidth, size])

  return (
    <div
      className={cx(styles['count-icon'], className)}
      style={iconStyle}
      {...props}>
      <div className={cx(styles['count'])}>
        {(count > 0) ? (<><FontAwesomeIcon icon={['fal', 'plus']} /> {count}</>) : null}
        {(label) ? (<span className={cx(styles['label'])}>{label}</span>) : null}
      </div>
    </div>
  )
}

CountIcon.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  label: PropTypes.string,
  size: PropTypes.string
}

CountIcon.defaultProps = {
  backgroundColor: 'primary',
  borderColor: 'rgba(255, 255, 255, 0)',
  borderWidth: '3px',
  className: '',
  size: '42px'
}

export default CountIcon
