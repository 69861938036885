import {
  RECEIVE_EDIT_STATE,
  RECEIVE_CONNECT_USER,
  RECEIVE_DISCONNECT_USER
} from '../../types'

const initialState = {}

const ACTION_HANDLERS = {
  [RECEIVE_EDIT_STATE]: (state, action) => {
    const editState = { ...action.data }
    const { uid } = editState
    delete editState.uid

    return {
      ...state,
      [uid]: editState
    }
  },
  [RECEIVE_CONNECT_USER]: (state, action) => {
    const userData = action.data
    const { uid } = userData

    if (uid === action.currentUserId) {
      return state
    }

    delete userData.uid
    return {
      ...state,
      [uid] : {
        ...state[uid],
        ...userData
      }
    }
  },
  [RECEIVE_DISCONNECT_USER]: (state, action) => {
    const newState = { ...state }
    const { uid } = action.data
    delete newState[uid]

    return newState
  }
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
