export const BOARDS = 'boards'
export const USERS = 'users'
export const DESTINATIONS = 'destinations'
export const BOARDS_HEADING = 'BOARDS'
export const USERS_HEADING = 'USERS'
export const DESTINATIONS_HEADING = 'DESTINATIONS'

export default {
  BOARDS,
  USERS,
  DESTINATIONS,
  BOARDS_HEADING,
  USERS_HEADING,
  DESTINATIONS_HEADING
}
